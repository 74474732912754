import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as PositionDefaultsRoute } from '@/routes/organizations.$organizationId.company.position-defaults';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { useIsMutating } from '@tanstack/react-query';
import {
  handleMapFormValuesToData,
  PositionCommunicationFormValues,
  usePositionCommunicationForm,
} from '@/lib/usePositionCommunicationForm';
import { PositionCommunicationForm } from '@/components/core/position/communication/communication-form';
import {
  useGetOrganizationCommunicationFormConfig,
  useUpdateOrganizationCommunicationFormConfigMutation,
} from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { mergeDataWithDefaults } from '@/components/core/position/communication/form-defaults';

export const Route = createRoute({
  getParentRoute: () => PositionDefaultsRoute,
  path: '/communication',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsCommunicationPage,
});

function CompanyPositionDefaultsCommunicationPage() {
  const { organizationId } = Route.useParams();
  const isMutating = useIsMutating();
  const form = usePositionCommunicationForm();
  const { handleSubmit, reset, watch } = form;
  const { data: organizationCommunicationFormConfig } =
    useGetOrganizationCommunicationFormConfig({
      organizationId: Number(organizationId),
    });
  const updateOrganizationCommunicationFormConfig =
    useUpdateOrganizationCommunicationFormConfigMutation();
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (organizationCommunicationFormConfig) {
      reset({
        ...mergeDataWithDefaults(organizationCommunicationFormConfig),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationCommunicationFormConfig]);

  const onSubmit = async (values: PositionCommunicationFormValues) => {
    const res = await updateOrganizationCommunicationFormConfig({
      data: handleMapFormValuesToData(values),
      organizationId: Number(organizationId),
    });
    if (res.data) {
      toast.success('Communication form updated successfully!');
    } else {
      toast.error(res.error);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 w-full">
        <PositionCommunicationForm />
        {isFormInitialized && (
          <Button
            isLoading={isMutating > 0}
            className="float-right"
            type="submit"
          >
            Save
          </Button>
        )}
      </form>
    </Form>
  );
}
