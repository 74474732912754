import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { HiringMember } from '@/fetchers/usePosition';
import { useState } from 'react';
import AlertModal from '../../common/AlertModal';
import { XIcon } from 'lucide-react';
import { useIsMutating } from '@tanstack/react-query';

const Member = ({
  member,
  onRemove,
}: {
  member: HiringMember;
  onRemove: (member: HiringMember) => Promise<void>;
}) => {
  const isMutating = useIsMutating();
  const [removeMemberAlertModalOpen, setRemoveMemberAlertModalOpen] =
    useState(false);
  const { user } = member;
  console.log('member', member);
  return (
    <>
      <AlertModal
        open={removeMemberAlertModalOpen}
        onClose={() => setRemoveMemberAlertModalOpen(false)}
        title="You are about to remove a team member from the hiring team"
        body={`Team member will still have access to the position but will not be part of the hiring team and will not receive notifications.`}
        confirmText="Remove Member"
        onProceed={async () => {
          await onRemove(member);
        }}
        isConfirmLoading={isMutating > 0}
      />
      <div className="flex flex-row items-center justify-between rounded-lg border p-4 text-sm ">
        <div className="flex items-center">
          <Avatar className="mr-2 size-10">
            <AvatarImage src={user.imageUrl || ''} alt={'Team member'} />
            <AvatarFallback>
              {user.email?.slice(0, 2)?.toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <div className="space-y-0.5">
            <div className="text-base">{user.fullName}</div>
            <div>{user.email}</div>
          </div>
        </div>

        <Button
          variant="outline"
          type="button"
          size="sm"
          onClick={() => {
            setRemoveMemberAlertModalOpen(true);
          }}
        >
          <XIcon className="size-5" />
        </Button>
      </div>
    </>
  );
};

export default Member;
