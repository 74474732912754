import * as React from "react";
const SvgLogoSmall = (props) => /* @__PURE__ */ React.createElement("svg", { width: 132, height: 104, viewBox: "0 0 132 104", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_16_31", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 20, y: 16, width: 112, height: 88 }, /* @__PURE__ */ React.createElement("path", { d: "M20.6666 16.8906H131.292V103.802H20.6666V16.8906Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_16_31)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask1_16_31", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 20, y: 16, width: 111, height: 88 }, /* @__PURE__ */ React.createElement("path", { d: "M56.8698 16.8906H123.781C126.094 16.8906 128.271 18.0052 129.625 19.8854C130.979 21.7656 131.349 24.1823 130.615 26.3802L107.969 94.3125C106.083 99.9792 100.781 103.802 94.8073 103.802H27.8958C25.5833 103.802 23.4063 102.688 22.0521 100.807C20.6979 98.9271 20.3281 96.5104 21.0625 94.3125L43.7083 26.3802C45.5938 20.7135 50.8958 16.8906 56.8698 16.8906Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_16_31)" }, /* @__PURE__ */ React.createElement("path", { d: "M17.8959 16.8906H133.667V103.802H17.8959V16.8906Z", fill: "#0558EE" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask2_16_31", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 120, height: 88 }, /* @__PURE__ */ React.createElement("path", { d: "M119.333 0H0.666626V88H119.333V0Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_16_31)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask3_16_31", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 112, height: 88 }, /* @__PURE__ */ React.createElement("path", { d: "M4.7135 0.895828H115.333V87.8021H4.7135V0.895828Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_16_31)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask4_16_31", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 4, y: 0, width: 112, height: 88 }, /* @__PURE__ */ React.createElement("path", { d: "M40.9687 0.895828H107.88C110.198 0.895828 112.375 2.01041 113.729 3.88541C115.083 5.76562 115.453 8.18229 114.719 10.3802L92.0728 78.3177C90.1822 83.9844 84.8801 87.8021 78.9114 87.8021H11.9999C9.68221 87.8021 7.50513 86.6927 6.15096 84.8125C4.79679 82.9323 4.427 80.5156 5.16138 78.3177L27.8072 10.3802C29.6978 4.71354 34.9999 0.895828 40.9687 0.895828Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask4_16_31)" }, /* @__PURE__ */ React.createElement("path", { d: "M1.99988 0.895828H117.766V87.8021H1.99988V0.895828Z", fill: "#21E8E6" })))));
export default SvgLogoSmall;
