import {
  createRootRouteWithContext,
  ErrorComponent,
  Outlet,
  ScrollRestoration,
} from '@tanstack/react-router';
// import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import { QueryClient } from '@tanstack/react-query';
import { lazy, Suspense } from 'react';
import { RouterContext } from '@/main';
import { Toaster } from '@/components/ui/sonner';
import { Loader } from '@/components/core/loader';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        import('@tanstack/router-devtools').then((m) => ({
          default: m.TanStackRouterDevtools,
        }))
      );

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  errorComponent: ({ error }) => {
    console.log('error @__root', error);
    return <ErrorComponent error={error} />;
  },
});

function RootComponent() {
  // const { auth: { isSignedIn, signOut } } = Route.useRouteContext()

  return (
    <Suspense fallback={<Loader />}>
      {/* <div className="p-2 flex gap-2 flex items-center">
        <Link to="/" className="[&.active]:font-bold">
          Home
        </Link>{' '}
        <Link to="/about" className="[&.active]:font-bold">
          About
        </Link>
        <Link
          to="/layout-a"
          activeProps={{
            className: 'font-bold',
          }}
        >
          Layout
        </Link>
        <Link
          to="/posts"
          activeProps={{
            className: 'font-bold',
          }}
        >
          Posts
        </Link>



        {isSignedIn && <Button onClick={() => signOut()}>Sign out</Button>}
        {!isSignedIn && <Link to='/signin'>Sign in</Link>}


      </div>
      <hr /> */}
      <ScrollRestoration />

      <Outlet />

      <Toaster />

      <ReactQueryDevtools buttonPosition="bottom-right" />
      <TanStackRouterDevtools />
    </Suspense>
  );
}
