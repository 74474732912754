import { Markdown } from '@/components/core/common/Markdown';
import { Loader } from '@/components/core/loader';
import {
  interviewVerdictValues,
  resumeVerdictValues,
} from '@/components/core/position/details/constants';

import {
  ApplicationDetail,
  useGetApplication,
} from '@/fetchers/useApplication';
import {
  cn,
  getContrastColorFromBackgroundColor,
  parseSalaryToDisplay,
} from '@/lib/utils';
import { createFileRoute, ErrorComponent } from '@tanstack/react-router';

import { useEffect, useRef } from 'react';
import { User2Icon } from 'lucide-react';
import generatePDF from 'react-to-pdf';
import logo from '@/assets/svg/logo-whitebg.svg';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useGetOrganizations } from '@/fetchers/useOrganization';

export const Route = createFileRoute(
  '/o/$organizationId/positions/print/$slug/$step/$id'
)({
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CandidateInfo,
});

export function CandidateInfo() {
  // @ts-ignore
  const { id } = Route.useParams();
  const { selectedOrganization } = useUserDetailStore();

  const { data: application, isFetching } = useGetApplication({
    applicationId: Number(id),
  });
  const interview = application?.interviews?.[0];

  const { isFetching: isFetcthingOrganizations } = useGetOrganizations({
    enabled: true,
  });
  // const navigate = useNavigate()
  const reportRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getApplicationStatus(application: any) {
    const interview = application?.interviews?.[0];

    if (interview?.finalVerdict) {
      return { status: 'completed', label: 'COMPLETED' };
    } else if (!interview?.finalVerdict && interview?.completedAt) {
      return { status: 'processesing', label: 'PROCESSING INTERVIEW' };
    } else if (!interview?.completedAt && interview?.startedAt) {
      return { status: 'in_progress', label: 'INTERVIEW IN PROGRESS' };
    } else if (interview && !interview?.startedAt) {
      return { status: 'interview_sent', label: 'INTERVIEW SENT' };
    } else if (!interview?.startedAt && application?.createdAt) {
      return { status: 'applied', label: 'APPLIED' };
    }
  }

  useEffect(() => {
    if (isFetching || isFetcthingOrganizations) return;
    if (!selectedOrganization) return;
    if (!selectedOrganization?.color) return;
    if (!selectedOrganization?.logoUrl) return;
    if (!selectedOrganization?.name) return;
    if (!application) return;

    // document.body.style.backgroundColor = selectedOrganization?.color || "#70e2ff"
    // document.body.style.color = getContrastColorFromBackgroundColor(selectedOrganization?.color || "#70e2ff")

    generatePDF(reportRef, {
      filename: 'page.pdf',
      page: {
        format: 'a4',
        orientation: 'portrait',
      },
    });

    setTimeout(() => {
      // navigate({
      //   to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
      //   params: {
      //     organizationId: `${selectedOrganization?.id || ""}`,
      //     // @ts-ignore
      //     slug: application?.position.slug,
      //     // @ts-ignore
      //     id: application?.id,
      //     step: "applications"
      //   }
      // })
      // close tab
      window.close();
    }, 1000);
  }, [isFetching, isFetcthingOrganizations, selectedOrganization, application]);

  if (isFetching || isFetcthingOrganizations) {
    return <Loader />;
  }

  const title = `${application?.position.title} position at ${selectedOrganization?.name}`;
  // const bgColor = selectedOrganization?.color || "#70e2ff"
  return (
    <>
      {/* <img src={image} alt="screenshot" /> */}

      <div
        ref={reportRef}
        className=" overflow-visible min-h-[100vh] 	"
        style={
          {
            // background: bgColor,
            // color: getContrastColorFromBackgroundColor(bgColor)
          }
        }
      >
        <Header
          bgColor={selectedOrganization?.color || '#70e2ff'}
          logo={selectedOrganization?.logoUrl || ''}
          title={title}
        />

        <div className="h-full p-8 space-y-12">
          <div className="p-8 shadow-lg rounded-lg">
            <div className="mb-6 flex justify-between items-center">
              <div className="flex flex-col xl:items-center xl:flex-row">
                <p className="font-bold text-3xl">{application?.name}</p>
                <div className="ml-2 mt-1">
                  <Badge className=" mt-6">
                    {getApplicationStatus(application)?.label}
                  </Badge>
                </div>
              </div>
              <div className="flex items-center justify-center">
                {application?.createdAt && (
                  <p className="text-gray-700 text-sm text-right">
                    Applied{' '}
                    {new Date(application?.createdAt).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                    )}
                  </p>
                )}
              </div>
            </div>

            <Avatar className="size-[100px] text-4xl text-gray-400 ">
              {application?.userAvatar && (
                <AvatarImage src={application.userAvatar} alt="User Avatar" />
              )}
              <AvatarFallback>
                <User2Icon className="size-12" />{' '}
              </AvatarFallback>
            </Avatar>

            <InformationTable application={application} />
          </div>

          {interview?.analysisSummary && (
            <div className="p-8 shadow-lg rounded-lg ">
              <div className="flex  items-center justify-between mb-6">
                <h2 className="text-2xl font-bold tracking-widest	">
                  Interview Summary
                </h2>

                <Badge>
                  {/* @ts-ignore */}
                  {interviewVerdictValues?.[
                    interview?.finalVerdict
                  ]?.toUpperCase()}
                </Badge>
              </div>
              <p>{interview?.analysisSummary}</p>
            </div>
          )}

          {application?.resumeAnalysis && (
            <div className="p-8 shadow-lg rounded-lg ">
              <div className="flex  items-center justify-between mb-6">
                <h2 className="text-2xl font-bold tracking-widest	">
                  Resume Analysis
                </h2>
                <Badge>
                  {resumeVerdictValues?.[
                    // @ts-ignore
                    application?.resumeAnalysisVerdict
                  ]?.toUpperCase()}
                </Badge>
              </div>
              <p>{application?.resumeAnalysis}</p>
            </div>
          )}

          {interview?.analysis && (
            <div
              className="shadow-lg rounded-lg p-8 h-full flex flex-col"
              style={{
                height: 'fit-content',
              }}
            >
              <h2 className="text-2xl font-bold mb-4 tracking-widest	">
                Interview Analysis
              </h2>
              <Markdown content={interview?.analysis} />
            </div>
          )}

          <div className="shadow-lg rounded-lg p-8">
            {/* <h2 className='text-2xl font-bold mb-4'>Additional Questions</h2> */}
            {interview?.additionalQuestionsAndAnswers && (
              <div className="prose">
                {interview?.additionalQuestionsAndAnswers.map(
                  (question, idx) => (
                    <div key={idx} className="mb-4">
                      <h3 className="text-lg font-bold tracking-widest	">
                        {question.question}
                      </h3>
                      <p>{question.answer}</p>
                    </div>
                  )
                )}
              </div>
            )}
          </div>

          <div className="w-full  z-10 mt-6 mb-4 block">
            <a
              href="https://veton.ai"
              target="_blank"
              className="p-4 text-lg flex justify-center items-center h-[60px] sticky bottom-0 left-0 w-full border-t border-gray-100"
            >
              Powered by{' '}
              <img
                alt="powered by veton.ai"
                src={logo}
                style={{ height: 25 }}
                className="ml-1 mt-4"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

function Badge({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'border border-black rounded-full px-2  flex items-center justify-center  h-[26px]  relative',
        className
      )}
    >
      <div className=" pb-4 ">{children}</div>
    </div>
  );
}

function InformationTable({
  application,
}: {
  application?: ApplicationDetail;
}) {
  if (!application) return null;
  return (
    <div className="space-y-8 mt-8  break-all w-full ">
      {application?.email && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Email</span>
          </p>
          <p>{handleUndefinedValue(application?.email)}</p>
        </div>
      )}

      {application?.phone && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Phone</span>
          </p>
          <p>{handleUndefinedValue(application?.phone)}</p>
        </div>
      )}

      {application?.linkedinProfile && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Linkedin Profile</span>
          </p>
          <p>{handleUndefinedValue(application?.linkedinProfile)}</p>
        </div>
      )}

      {application?.additionalInfo && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Additional Information</span>
          </p>
          <p
            style={{
              wordBreak: 'break-word',
            }}
          >
            {handleUndefinedValue(application?.additionalInfo)}
          </p>
        </div>
      )}

      {application?.desiredSalary && application?.salaryCurrency && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Desired Salary</span>
          </p>
          {application?.desiredSalary === 'undefined' ? (
            <p>Blank</p>
          ) : (
            <p>
              {parseSalaryToDisplay(
                Number(application?.desiredSalary),
                application?.salaryCurrency
              )}{' '}
              / {application?.salaryType}
            </p>
          )}
        </div>
      )}

      {application?.education && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Education</span>
          </p>
          <p>{handleUndefinedValue(application?.education)}</p>
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleUndefinedValue(value: any) {
  if (value === 'undefined') {
    return 'Blank';
  } else {
    return value;
  }
}

function Header({
  logo,
  title,
  bgColor = '#70e2ff',
}: {
  bgColor: string;
  logo: string;
  title: string;
}) {
  const color = getContrastColorFromBackgroundColor(bgColor);
  return (
    <div
      className="w-full sticky top-0 z-10 mt-6  block py-4 tracking-widest	"
      style={{
        background: bgColor,
        color: color,
      }}
    >
      <div className="container w-full  py-2  flex justify-between  flex-col md:flex-row  ">
        <div className="flex  items-center justify-center ">
          <div className="rounded overflow-hidden rounded-xl">
            <img
              src={logo}
              // className="object-fit"
              alt="Company Logo"
              style={{
                // width: "60px",
                height: '80px',
                // objectFit: "fill",
                aspectRatio: 1,
              }}
            />
          </div>

          <div className="flex  flex-col ml-4 items-center justify-center ">
            <p className="font-bold text-2xl pb-4">{title}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
