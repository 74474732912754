import { useEffect, useState, useRef } from 'react';
import { useMediaRecorder } from '@/lib/useMediaHanlder/useMediaRecorder';
import { useWebcamRecorder } from '@/lib/useMediaHanlder/useWebcamRecorder';
import { uploadRecording } from '@/lib/useMediaHanlder/upload';
import { useMediaStore } from '@/lib/useMediaHanlder/useMediaStore';

export function useVocalConversation({
  interviewId,
}: {
  interviewId?: number;
}) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const audioFormat = isSafari ? 'mp4' : 'webm';
  const mimeType = isSafari ? 'audio/mp4' : 'audio/webm';
  // const [uploading, setUploading] = useState(false);

  const uploadQueue = useRef<
    Array<{ blob: Blob; url: string; isVideo: boolean }>
  >([]);
  const [uploadingUrls, setUploadingUrls] = useState<Record<string, boolean>>(
    {}
  );
  const { setCurrentPresignedUrl } = useMediaStore();
  const { startRecording, stopRecording, mediaRecorder, cleanUpStream } =
    useMediaRecorder(audioFormat, mimeType);
  const { currentPresignedUrl } = useMediaStore();

  const webcamManager = useWebcamRecorder();

  // const getPresignedUrl = usePresignedUrlMutation({
  //   interviewId: Number(interviewId),
  // });

  async function uploadMedia() {
    try {
      if (!interviewId) return;
      if (!currentPresignedUrl) return;

      if (uploadingUrls[currentPresignedUrl]) {
        console.log('Upload already in progress for this URL');
        return;
      }

      setUploadingUrls((prev) => ({ ...prev, [currentPresignedUrl]: true }));

      const isVideo = webcamManager?.userSetCamereOn;
      let blob: Blob | null = null;

      if (isVideo) {
        console.log('Preparing user video');
        await webcamManager?.stop(async (videoBlob: Blob) => {
          console.log('Webcam stopped => getting blob from webcam');
          blob = videoBlob;
        });
      } else {
        console.log('Preparing user audio');
        // @ts-ignore
        blob = await stopRecording();
      }

      if (!blob) {
        console.log('No blob available');
        return;
      }

      uploadQueue.current.push({ blob, url: currentPresignedUrl, isVideo });
      processUploadQueue();
    } catch (error) {
      console.error('Error preparing media for upload:', error);
    } finally {
      setCurrentPresignedUrl(null);
    }
  }

  async function processUploadQueue() {
    while (uploadQueue.current.length > 0) {
      const { blob, url, isVideo } = uploadQueue.current[0];
      try {
        console.log(`Uploading ${isVideo ? 'video' : 'audio'}`, url);
        await uploadRecording({
          recording: blob,
          presignedUrl: url,
          mediaType: isVideo ? 'video/webm' : 'audio/webm',
        });
        console.log('Upload successful:', url);
        await webcamManager.clearAllRecordings();
      } catch (error) {
        console.error('Error uploading media:', error);
      } finally {
        uploadQueue.current.shift();
        setUploadingUrls((prev) => ({ ...prev, [url]: false }));
      }
    }
  }

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function () {
        // setTimeout(() => {
        //   webcamManager.start();
        // }, 5000);
      })
      .catch(function () {
        console.log('No mic found');
      });
  }, []);

  useEffect(() => {
    const handleDeviceChange = async () => {
      console.log('Device change detected');
      if (
        mediaRecorder.current &&
        mediaRecorder.current.state === 'recording'
      ) {
        console.log('Stopping recording due to device change');
        // await stopRecording();
        // manager.abortListening();
        // while (manager.listening) {
        //   await manager.abortListening();
        // }
        console.log('Restarting recording');
        await startRecording();
      }
    };

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      );
      cleanUpStream(); // Ensure the stream is cleaned up on unmount
    };
  }, [startRecording, stopRecording]);

  async function recordMedia() {
    startRecording();
    await webcamManager?.record();
  }

  return {
    recordMedia,
    uploadMedia,
    webcamManager,
    stopRecording,
  };
}
