import DOMPurify from 'dompurify';

import { marked } from 'marked';

type TProps = {
  content: string;
};
export function Markdown({ content }: TProps) {
  const parsedContent = marked.parse(content);
  if (!parsedContent) {
    return null;
  }
  return (
    <div
      className="prose"
      // @ts-ignore - ?? marked.parse claims to return promise but this works just fine
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parsedContent) }}
    />
  );
}
