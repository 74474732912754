import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormProps } from 'react-hook-form';
import { TAIConfigForm } from '@/fetchers/usePosition';

const positionAiConfigFormSchema = z.object({
  voiceModel: z.enum(['shimmer', 'echo', 'nova', 'onyx']).default('echo'),
  additionalQuestions: z
    .array(
      z.object({
        value: z.string(),
      })
    )
    .optional(),
  restrictQuestions: z
    .enum(['enabled', 'disabled'])
    .default('disabled')
    .optional(),
  isInitialized: z.boolean().default(false).optional(),
});

export type PositionAIConfigFormValues = z.infer<
  typeof positionAiConfigFormSchema
>;

export const positionAiConfigFormDefaultValues: Partial<PositionAIConfigFormValues> =
  {
    voiceModel: 'echo',
    additionalQuestions: [{ value: '' }],
    restrictQuestions: 'disabled',
  };

export const handleMapAIConfigDataToFormValues = (
  data: TAIConfigForm
): PositionAIConfigFormValues => {
  const remoteQuestions =
    data?.additionalQuestions?.map((value) => ({ value })) || [];
  const additionalQuestions =
    remoteQuestions?.length > 0
      ? remoteQuestions
      : positionAiConfigFormDefaultValues.additionalQuestions;
  return {
    voiceModel:
      data?.voiceModel || positionAiConfigFormDefaultValues.voiceModel,
    additionalQuestions: additionalQuestions,
    restrictQuestions: data?.restrictQuestions ? 'enabled' : 'disabled',
  };
};

export const usePositionAiConfigForm = (
  options?: UseFormProps<PositionAIConfigFormValues>
) => {
  return useForm<PositionAIConfigFormValues>({
    resolver: zodResolver(positionAiConfigFormSchema),
    mode: 'onChange',
    ...options,
  });
};
