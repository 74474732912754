import { Form } from '@/components/ui/form';

import { Button } from '@/components/ui/button';

import {
  HiringMember,
  useAddHiringTeamMember,
  useHiringTeamMembers,
  useRemoveHiringTeamMember,
} from '@/fetchers/usePosition';

import { useIsMutating } from '@tanstack/react-query';
import { useNavigate, useParams } from '@tanstack/react-router';

import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { HiringTeamForm } from './hiring-team-form';
import { usePositionHiringTeamForm } from '@/lib/usePositionHiringTeamForm';
import { toast } from 'sonner';
import { TeamMember } from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';

export function HiringTeamFormContainer() {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false });
  const { creating } = usePositionManageRouteSearch();
  const parsedOrganizationId = Number(organizationId);
  const navigate = useNavigate();
  const { selectedOrganization } = useUserDetailStore();
  const isMutating = useIsMutating();
  const { data: hiringTeamMembers } = useHiringTeamMembers({
    organizationId: Number(organizationId),
    slug,
  });
  const form = usePositionHiringTeamForm();
  const { reset, watch } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (hiringTeamMembers) {
      reset({ hiringTeamMembers: hiringTeamMembers, isInitialized: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiringTeamMembers]);

  const removeMember = useRemoveHiringTeamMember();
  const addMember = useAddHiringTeamMember();

  const handleRemoveMember = async (member: HiringMember) => {
    const res = await removeMember({
      organizationId: parsedOrganizationId,
      email: member.user.email,
      slug,
    });
    if (res.data?.success) {
      toast.success('Member has been removed successfully!');
    } else {
      toast.error(res.error);
    }
  };

  const handleAddMember = async (member: TeamMember) => {
    const res = await addMember({
      organizationId: parsedOrganizationId,
      slug: slug,
      member: member.email,
    });
    if (res.data?.id) {
      toast.success('Member has been added successfully!');
    } else {
      toast.error(res.error);
    }
  };

  const handleAddAllMembers = async () => {
    const res = await addMember({
      organizationId: parsedOrganizationId,
      slug: slug,
      member: 'all',
    });
    if (res.data?.id) {
      toast.success('All members have been added successfully!');
    } else {
      toast.error(res.error);
    }
  };

  return (
    <>
      <Form {...form}>
        <form className="space-y-8">
          <HiringTeamForm
            onRemoveMember={handleRemoveMember}
            onAddMember={handleAddMember}
            onAddAllMembers={handleAddAllMembers}
          />
          {creating && isFormInitialized && (
            <Button
              type="button"
              disabled={isMutating > 0}
              className="float-right"
              onClick={() => {
                navigate({
                  // to: "/new-position/$step",
                  to: '/organizations/$organizationId/positions/manage/$slug/$step',
                  params: {
                    organizationId: String(organizationId),
                    slug: slug,
                    step: selectedOrganization?.integrationName
                      ? 'applications'
                      : 'preview',
                  },
                });
              }}
            >
              Next
            </Button>
          )}
        </form>
      </Form>
    </>
  );
}
