import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as CompanyRoute } from '@/routes/organizations.$organizationId.company';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, buttonVariants } from '@/components/ui/button';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { toast } from 'sonner';
import { Input } from '@/components/ui/input';
import { TextEditor } from '../components/core/common/TextEditor';
import { ColorPicker } from '../components/core/common/ColorPicker';
import { RequiredStar } from '../components/core/common/RequiredStar';
import {
  useOrganizationStore,
  useUpdateOrganization,
} from '@/fetchers/useOrganization';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { Link, useNavigate, useRouteContext } from '@tanstack/react-router';
import { alphaNumericRegex, cn } from '@/lib/utils';
import ImageCropper from '../components/core/common/ImageCropper';
import { useState } from 'react';
import SlugInput from '../components/core/common/SlugInput';
import { VetonIndicator } from '../components/core/common/VetonIndicator';
import { Breadcrumb } from '@/components/core/breadcrumb';

export const Route = createRoute({
  getParentRoute: () => CompanyRoute,
  path: '/settings',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanySettingsPage,
});

const displayFormSchema = z.object({
  logo: z.union([z.string(), z.instanceof(File)]).optional(),
  name: z.string({
    required_error: 'Company name is required.',
  }),
  slug: z
    .string({
      required_error: 'Company Slug is required.',
    })
    .regex(alphaNumericRegex, {
      message: 'Company name should only contain alphanumeric characters.',
    }),
  color: z.string().optional(),
  content: z.string({
    required_error: 'Content is required.',
  }),

  companyReachOutEmail: z
    .string({
      required_error: 'Email is required.',
    })
    .email(),
});

type DisplayFormValues = z.infer<typeof displayFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<DisplayFormValues> = {
  logo: undefined,
  name: undefined,
  content: undefined,
  color: '#70e2ff',
  slug: undefined,
  companyReachOutEmail: undefined,
};

export function CompanySettingsPage() {
  const { organizationId } = Route.useParams();
  const { selectedOrganization } = useUserDetailStore();
  const updateOrganization = useUpdateOrganization();
  const {
    auth: { user },
  } = useRouteContext({ strict: false });
  const navigate = useNavigate();
  const [cropperOpen, setCropperOpen] = useState(false);
  const { selectedParentOrganization } = useOrganizationStore();

  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues: {
      color: selectedOrganization?.color || defaultValues.color,
      name: selectedOrganization?.name || defaultValues.name,
      logo:
        selectedOrganization?.logoUrl ||
        selectedParentOrganization?.logoUrl ||
        defaultValues.logo,
      content:
        selectedOrganization?.companyDescription || defaultValues.content,

      slug: selectedOrganization?.slug || defaultValues.slug,
      companyReachOutEmail:
        selectedOrganization?.companyReachOutEmail ||
        user?.emailAddresses?.[0]?.emailAddress ||
        defaultValues.companyReachOutEmail,
    },
  });

  async function onSubmit(data: DisplayFormValues) {
    const organizationId = selectedOrganization?.id || -1;
    if (!selectedParentOrganization?.id) {
      toast.error('Please select a parent organization.');
      return;
    }

    const res = await updateOrganization({
      organizationId,
      parentOrganizationId: selectedParentOrganization?.id,
      payload: {
        name: data.name,
        logo: typeof data.logo === 'string' ? undefined : data.logo,
        color: data.color,
        companyDescription: data.content,
        slug: data?.slug,
        companyReachOutEmail: data.companyReachOutEmail,
      },
    });
    if (res?.id) {
      navigate({
        to: '/organizations/$organizationId/positions/list',
        params: { organizationId: String(res.id) },
      });
    } else {
      toast.error('Something went wrong. Please try again.');
    }
  }

  const isCreate = organizationId === 'create';

  return (
    <div className="flex flex-col">
      <Breadcrumb
        items={[
          {
            title: isCreate ? 'Create Board' : 'Board Settings',
          },
        ]}
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-8 w-full max-w-4xl mx-auto"
        >
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <h2 className="text-2xl font-bold tracking-tight ">
                Board Settings
              </h2>
              <p className="text-muted-foreground">Setup your board</p>
            </div>
            {!isCreate && (
              <Link
                to={
                  selectedOrganization?.integrationName
                    ? '/organizations/$organizationId/company/position-defaults/communication'
                    : '/organizations/$organizationId/company/position-defaults/application'
                }
                params={{
                  organizationId,
                }}
                className={buttonVariants({ variant: 'outline' })}
              >
                Position defaults
              </Link>
            )}
          </div>
          <div className="w-full h-full max-h-screen overflow-y-auto pb-[200px] md:pb-[160px] space-y-8 px-1">
            <FormField
              control={form.control}
              name="logo"
              render={({ field }) => (
                <FormItem className="max-w-md">
                  <FormLabel>Board Logo (260 x 260)</FormLabel>
                  <FormDescription>
                    Upload a logo for this specific board to display on the
                    careers page for all positions on this job board.
                  </FormDescription>

                  {field.value && (
                    <ImageCropper
                      open={cropperOpen}
                      setOpen={setCropperOpen}
                      onChange={field.onChange}
                      aspectRatio={1}
                      defaultValue={field.value}
                      layoutClasses="size-[260px]"
                    />
                  )}

                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex space-x-4">
              <FormField
                control={form.control}
                name="name"
                // disabled={!!selectedOrganization?.name}
                render={({ field }) => (
                  <FormItem className="w-full max-w-sm">
                    <FormLabel aria-required>
                      Board Name
                      <RequiredStar />
                    </FormLabel>
                    <FormDescription></FormDescription>
                    <FormControl>
                      <Input placeholder="Board name" {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="companyReachOutEmail"
              // disabled={!!selectedOrganization?.name}
              render={({ field }) => (
                <FormItem className="max-w-sm w-full">
                  <FormLabel aria-required>
                    Board Email
                    <RequiredStar />
                  </FormLabel>
                  <FormDescription>
                    This will be the main point of contact for candidates and
                    will be public
                  </FormDescription>
                  <FormControl>
                    <Input placeholder="Board raech out email" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex flex-col space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row">
              <FormField
                control={form.control}
                name="slug"
                // disabled={!!selectedOrganization?.name}
                render={({ field }) => (
                  <FormItem
                    className={cn(
                      'max-w-xs',
                      selectedOrganization?.slug &&
                        'opacity-50 pointer-events-none'
                    )}
                  >
                    <FormLabel aria-required>
                      Board Slug
                      <RequiredStar />
                    </FormLabel>
                    <FormDescription>
                      This cannot be changed later
                      {/* <p className="font-bold">Cannot be changed once it is set</p> */}
                      {/* Should be alphanumeric characters only, as it will be used in your careers page. */}
                    </FormDescription>
                    <FormControl>
                      {/* <Input placeholder="Company slug" {...field} /> */}
                      <SlugInput {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="color"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>Board Color</FormLabel>
                    <FormDescription>
                      <p className="invisible">Board Color</p>
                    </FormDescription>
                    <FormControl>
                      <ColorPicker {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Company Description for this Board
                    <VetonIndicator required />
                  </FormLabel>
                  <FormDescription>
                    {/* Detailed company description. <br /> This will be displayed on your company's veton careers page, and will be referred by our AI during the interviews. */}
                    This will be displayed on the top of the board, and will be
                    referred by our AI during the interviews. <br />
                    We recommend a few sentences to a single paragraph
                  </FormDescription>
                  <FormControl>
                    <TextEditor
                      // @ts-expect-error style works
                      // style={{ height: "80%" }}
                      className="h-[350px] pb-20"
                      theme="snow"
                      placeholder="An introduction to your company for candidates on this board"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              isLoading={form.formState.isSubmitting}
              className="float-right"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
