import axios from 'axios';
import { convertToCamelCase } from './utils';

export const fetcher = axios.create({
  baseURL: import.meta.env.VITE_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

export async function fetchPaginatedData<T>({
  url,
  token,
  pageParam = 1,
}: {
  url: string;
  token: string | null;
  pageParam?: number;
}) {
  const separator = url.includes('?') ? '&' : '?';
  const paginatedUrl = `${url}${separator}page=${pageParam}`;

  const response = await fetcher.get<{ items: T[]; count: number }>(
    paginatedUrl,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const hasNextPage = response.data.items?.length > 0;

  return {
    data: convertToCamelCase(response.data.items) as T[],
    nextPage: hasNextPage ? pageParam + 1 : undefined,
    totalCount: response.data.count,
  };
}
