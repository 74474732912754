import { useState } from 'react';
import { Link } from '@tanstack/react-router';
import { LucideIcon } from 'lucide-react';
import {
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/components/ui/sidebar';
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from '@/components/ui/collapsible';

import './sidebar.css';

export type TSidebarMenuItem = {
  title: string;
  icon: LucideIcon;
  isActive?: boolean;
  isCollapsibleOpen?: boolean;
  to?: string;
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
  subItems?: TSidebarMenuItem[];
};

export const CustomSidebarMenuItem = ({ link }: { link: TSidebarMenuItem }) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);

  const linkElement = (
    <Link
      // @ts-ignore
      to={link.to}
      href={link.href ?? undefined}
      target={link.href ? '_blank' : undefined}
      onClick={link.onClick}
      disabled={link.disabled}
      preload={false}
    >
      <link.icon />
      {link.title}
    </Link>
  );
  const isOpen = isCollapsibleOpen || link.isCollapsibleOpen;

  return link.subItems?.length ? (
    <Collapsible
      open={isOpen}
      className="group/collapsible"
      onOpenChange={setIsCollapsibleOpen}
    >
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton isActive={link.isActive} asChild>
            {linkElement}
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            {link.subItems.map((subLink) => (
              <SidebarMenuSubItem key={subLink.title}>
                {subLink.to ? (
                  <SidebarMenuSubButton asChild isActive={subLink.isActive}>
                    <Link
                      // @ts-ignore
                      to={subLink.to}
                      href={subLink.href ?? undefined}
                      target={subLink.href ? '_blank' : undefined}
                      onClick={subLink.onClick}
                      disabled={subLink.disabled}
                      preload={false}
                    >
                      <subLink.icon />
                      <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                        {subLink.title}
                      </span>
                    </Link>
                  </SidebarMenuSubButton>
                ) : (
                  <span className="font-bold">{subLink.title}</span>
                )}
              </SidebarMenuSubItem>
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  ) : (
    <SidebarMenuItem>
      <SidebarMenuButton asChild isActive={link.isActive}>
        {linkElement}
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};
