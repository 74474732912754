import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createRouter } from '@tanstack/react-router';

// Import the generated route tree
import { routeTree } from './routeTree.gen';
import {
  ClerkLoaded,
  ClerkProvider,
  useClerk,
  useUser,
} from '@clerk/clerk-react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import './index.css';
import { ThemeProvider } from './lib/theme';
// import { usePanelStore } from './lib/usePanelStore'
import { useUserDetailStore } from './fetchers/useUserDetails';
import { toast } from 'sonner';
import {
  useGetBilling,
  useOrganizationStore,
} from './fetchers/useOrganization';
import * as Sentry from '@sentry/react';
import { IntercomProvider } from 'react-use-intercom';

const INTERCOM_APP_ID = 'ql44u3o1';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
    environment: window.location.hostname.startsWith('development.app')
      ? 'development'
      : 'production',
  });
}

// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
const rootElement = document.getElementById('app')!;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
  queryCache: new QueryCache({
    onError: (error) =>
      toast.error(`Something went wrong`, {
        description: `${error.message}`,
      }),
  }),
});

export interface RouterContext {
  auth: {
    user: ReturnType<typeof useUser>['user'];
    isSignedIn: ReturnType<typeof useUser>['isSignedIn'];
    // details?: ReturnType<typeof useUserDetails>;
    signOut: ReturnType<typeof useClerk>['signOut'];
  };
  queryClient: QueryClient;
  // organizations: ReturnType<typeof useOrganizationQuery>['data']
}

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  context: {
    auth: {
      user: null,
      isSignedIn: false,
      // session: null,
      // details: undefined,
      signOut: async () => {},
    },
    queryClient,
    // organizations: []
  },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

// eslint-disable-next-line react-refresh/only-export-components
function Root() {
  const userInfo = useUser();

  // const userDetails = useUserDetails({
  //   enabled: userInfo.isSignedIn
  // })

  const { setSelectedOrganization } = useUserDetailStore();
  useGetBilling({});
  const { setSelectedParentOrganization } = useOrganizationStore();

  const { signOut } = useClerk();

  const auth = {
    user: userInfo.user,
    isSignedIn: userInfo.isSignedIn,
    // session: sessionInfo.session,
    // ready: !userDetails?.isFetching,
    // details: userDetails,
    signOut: async () => {
      setSelectedOrganization();
      setSelectedParentOrganization();
      await signOut();
      router.navigate({
        to: '/signin',
      });
    },
  };

  // const { setRightPanelComponent, setRightPanelSize } = usePanelStore()

  // useLayoutEffect(() => {
  //   setRightPanelComponent()
  //   setRightPanelSize()
  // }, [])

  return (
    <>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <RouterProvider router={router} context={{ auth, queryClient }} />
      </ThemeProvider>
    </>
  );
}

// Render the app
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ClerkProvider
        publishableKey={PUBLISHABLE_KEY}
        localization={{
          signIn: {
            start: {
              actionText: "Don't have an account?",
              subtitle: '',
            },
          },
          signUp: {
            start: {
              actionText: 'Have an account?',
              subtitle: '',
            },
            emailCode: {
              title: 'Verify your email',
              subtitle: '',
            },
          },
        }}
      >
        <ClerkLoaded>
          <QueryClientProvider client={queryClient}>
            <IntercomProvider
              autoBootProps={{
                actionColor: '#000',
              }}
              appId={INTERCOM_APP_ID}
              // autoBoot={false}
              // autoBoot={!window.location.href.includes("interviews") && !window.location.href.includes("careers") && !window.location.href.includes("signin")}
              autoBoot={['interviews', 'careers', 'signin'].every(
                (path) => !window.location.href.includes(path)
              )}
            >
              <Root />
            </IntercomProvider>
          </QueryClientProvider>
        </ClerkLoaded>
      </ClerkProvider>
    </StrictMode>
  );
}
