import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { TriangleAlert } from 'lucide-react';

import { Label } from '@/components/ui/label';
// import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { RequiredStar } from '../common/RequiredStar';
import { useState } from 'react';
import { useParams } from '@tanstack/react-router';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZohoRecruitConnection({ info }: any) {
  // @ts-ignore
  const { organizationId } = useParams({ strict: false });
  const [region, setRegion] = useState('com');
  // const [clientSecret, setClientSecret] = useState("")
  // const [clientId, setClientId] = useState("")
  // const form = useForm<ConnectionValues>({
  //   resolver: zodResolver(connectionSchema),
  // })

  // function onSubmitHandler(data: ConnectionValues) {
  //   console.log(data)
  // }

  return (
    <div className="w-full flex flex-col items-center space-y-6">
      <img src={info?.bigImageUrl} className="h-[80px] object-center my-4" />
      <Alert className="text-left ">
        <TriangleAlert className="h-4 w-4" />
        <AlertTitle>Administrator role required</AlertTitle>
        <AlertDescription>
          You must be an administrator of Zoho Recruit in order to link to Veton
          successfully.
        </AlertDescription>
      </Alert>

      <div className="w-full space-y-4">
        <div>
          <Label htmlFor="datacenter">
            Datacenter Region
            <RequiredStar />
          </Label>
          <Select defaultValue={region} onValueChange={(val) => setRegion(val)}>
            <SelectTrigger>
              <SelectValue placeholder="Select your datacenter" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="com">
                <span className="font-medium">US</span>{' '}
                <span className="text-muted-foreground">(.com)</span>
              </SelectItem>
              <SelectItem value="eu">
                <span className="font-medium">Europe</span>{' '}
                <span className="text-muted-foreground">(.eu)</span>
              </SelectItem>


            </SelectContent>
          </Select>
        </div>

        {/* 
        <div>
          <Label htmlFor="client_id">Client ID<RequiredStar /></Label>
          <Input
            type="text"
            id="client_id"
            placeholder="Your Client ID"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            className="w-full"
          />
        </div>



        <div>
          <Label htmlFor="client_secret">Client Secret<RequiredStar /></Label>
          <Input
            type="text"
            id="client_secret"
            placeholder="Your Client Secret"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
            className="w-full"
          />
        </div> */}
      </div>

      <div className="self-end">
        <Button
          onClick={() => {
            const redirectUri = `${window.location.origin}/organizations`;

            const state = encodeURIComponent(
              JSON.stringify({
                organizationId: organizationId,
                integrationType: 'ZOHO_RECRUIT',
                authMethod: 'OAUTH2',
                redirectUri,
                // cliId: clientId,
                // cliSecret: clientSecret,
              })
            );

            const url = `https://accounts.zoho.${region}/oauth/v2/auth?scope=ZohoRecruit.modules.READ,ZohoRecruit.modules.CREATE,ZohoRecruit.modules.UPDATE,ZohoRecruit.settings.ALL&client_id=1000.8WA9R94QIT2GYNZ0BUII1W2SS547ED&response_type=code&access_type=offline&redirect_uri=${redirectUri}&state=${state}`;
            console.log('url', url);
            window.open(url, '_blank');
          }}
          className="w-full"
        >
          Connect
        </Button>
      </div>
    </div>
  );
}

// http://localhost:3000/organizations?code=1000.816995412c75e3dda13052ca1bc503f7.9e806ae6555e57244e1c61bf0b6b339d&location=eu&accounts-server=https%3A%2F%2Faccounts.zoho.eu&
