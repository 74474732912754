import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormProps } from 'react-hook-form';
import { z } from 'zod';

const hiringTeamFormSchema = z.object({
  hiringTeamMembers: z.array(
    z.object({
      id: z.number(),
      user: z.object({
        email: z.string(),
        username: z.string(),
        id: z.number(),
        fullName: z.string(),
        imageUrl: z.string(),
      }),
    })
  ),
  isInitialized: z.boolean().default(false).optional(),
});

export type HiringTeamFormValues = z.infer<typeof hiringTeamFormSchema>;

export const usePositionHiringTeamForm = (
  options?: UseFormProps<HiringTeamFormValues>
) => {
  return useForm<HiringTeamFormValues>({
    resolver: zodResolver(hiringTeamFormSchema),
    ...options,
  });
};
