import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { TextEditor } from '../../common/TextEditor';
import { RequiredStar } from '../../common/RequiredStar';
import { useRef, useState } from 'react';
import { InsertVariables } from './InsertVariables';
import { autoSendOptions, delayLabels } from './templates';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Input } from '@/components/ui/input';
import { useFormContext } from 'react-hook-form';
import { PositionCommunicationFormValues } from '@/lib/usePositionCommunicationForm';
import { Loader } from '../../loader';

export function PositionCommunicationForm() {
  const { control, watch, setValue, getValues } =
    useFormContext<PositionCommunicationFormValues>();
  const applicationEmailRef = useRef();
  const interviewCompletedEmailRef = useRef();
  const disqualifiedRef = useRef();
  const afterApplicationSubjectInputRef = useRef<HTMLInputElement | null>(null);
  const afterInterviewSubjectInputRef = useRef<HTMLInputElement | null>(null);
  const disqualifiedSubjectInputRef = useRef<HTMLInputElement | null>(null);
  const [focusedSubjectInput, setFocusedSubjectInput] = useState<
    | 'afterApplicationSubject'
    | 'afterInterviewSubject'
    | 'disqualifiedSubject'
    | null
  >(null);

  const autoInterview = watch('autoInterview');
  const isInitialized = watch('isInitialized');

  const handleInsertVariableToSubjectField = (variable: string) => {
    if (!focusedSubjectInput) return;
    let inputRef: HTMLInputElement | null = null;
    let currentValue = '';

    switch (focusedSubjectInput) {
      case 'afterApplicationSubject':
        inputRef = afterApplicationSubjectInputRef.current;
        currentValue = getValues('afterApplicationSubject');
        break;
      case 'afterInterviewSubject':
        inputRef = afterInterviewSubjectInputRef.current;
        currentValue = getValues('afterInterviewSubject');
        break;
      case 'disqualifiedSubject':
        inputRef = disqualifiedSubjectInputRef.current;
        currentValue = getValues('disqualifiedSubject');
        break;
      default:
        break;
    }

    const selectionStart = inputRef?.selectionStart ?? 0;
    const selectionEnd = inputRef?.selectionEnd ?? 0;

    const newValue =
      currentValue.substring(0, selectionStart) +
      variable +
      currentValue.substring(selectionEnd);

    switch (focusedSubjectInput) {
      case 'afterApplicationSubject':
        setValue('afterApplicationSubject', newValue);
        break;
      case 'afterInterviewSubject':
        setValue('afterInterviewSubject', newValue);
        break;
      case 'disqualifiedSubject':
        setValue('disqualifiedSubject', newValue);
        break;
      default:
        break;
    }

    // Restore cursor position after React updates the DOM
    setTimeout(() => {
      const newCursorPosition = selectionStart + variable.length;
      inputRef?.focus();
      inputRef?.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  if (!isInitialized) {
    return <Loader className="static h-[50vh] bg-transparent" />;
  }
  return (
    <>
      <FormField
        control={control}
        name="selectedMethod"
        render={({ field }) => (
          <FormItem>
            <div className="w-full flex items-center justify-between ">
              <FormLabel>Preferred Communication Method</FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="email">Email</TabsTrigger>
                    <TabsTrigger value="sms">SMS</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormMessage />

            <FormDescription>
              SMS option only available for US numbers.
            </FormDescription>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="interviewType"
        render={({ field }) => (
          <FormItem>
            <div className="flex justify-between items-center w-full ">
              <FormLabel>Preferred Interview Method </FormLabel>
              <FormControl>
                <Tabs value={field.value} onValueChange={field.onChange}>
                  <TabsList>
                    <TabsTrigger value="phone">Phone Call</TabsTrigger>
                    <TabsTrigger value="video">Video Call</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>

            <FormDescription>
              <p>
                Whether you prefer the AI to conduct interviews via phone or
                video call.
              </p>
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="autoInterview"
        render={({ field }) => (
          <FormItem>
            <div className="w-full flex items-center justify-between">
              <FormLabel>Auto Send Interviews</FormLabel>
              <FormControl>
                <Tabs
                  value={field.value ? 'enabled' : 'disabled'}
                  onValueChange={(val) => field.onChange(val === 'enabled')}
                >
                  <TabsList>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    <TabsTrigger value="enabled">Enabled</TabsTrigger>
                  </TabsList>
                </Tabs>
              </FormControl>
            </div>
            <FormDescription>
              Automatically send interview requests to candidates.
            </FormDescription>

            <FormMessage />
          </FormItem>
        )}
      />

      {autoInterview && (
        <FormField
          control={control}
          name="autoSendOption"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full  ">
                <FormLabel className="w-full">Auto Send to</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl className="w-[180px]">
                    <SelectTrigger>
                      <SelectValue placeholder="Auto send option" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.keys(autoSendOptions).map((key) => (
                      <SelectItem key={key} value={key}>
                        {/* @ts-ignore */}
                        {autoSendOptions[key]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <FormDescription>
                Choose to send all candidates or only good fits.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {autoInterview && (
        <FormField
          control={control}
          name="interviewDelay"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center w-full  ">
                <FormLabel className="w-full">Interview Delay</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl className="w-[140px]">
                    <SelectTrigger>
                      <SelectValue placeholder="Select interview delay" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.keys(delayLabels).map((key) => (
                      <SelectItem key={key} value={key}>
                        {/* @ts-ignore */}
                        {delayLabels[key]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <FormDescription>
                How soon after applying would you like to invite candidates to
                perform their interview
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      <div className="space-y-2">
        <FormLabel className="text-md">
          Interview Invitation Message
          <RequiredStar />
        </FormLabel>
        <FormField
          control={control}
          name="afterApplicationSubject"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  ref={(ref) => {
                    field.ref(ref);
                    afterApplicationSubjectInputRef.current = ref;
                  }}
                  onFocus={() => {
                    setFocusedSubjectInput('afterApplicationSubject');
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="afterApplication"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <TextEditor
                  // @ts-expect-error style works
                  // style={{ height: "80%" }}
                  className="h-[300px] pb-20"
                  theme="snow"
                  placeholder="Company and Job Content. ie: Your company's mission, vision, and values. Job description, responsibilities, and requirements."
                  onFocus={() => {
                    if (focusedSubjectInput) {
                      setFocusedSubjectInput(null);
                    }
                  }}
                  {...field}
                  ref={(ref) => {
                    field.ref(ref);
                    // @ts-ignore
                    applicationEmailRef.current = ref;
                  }}
                />
              </FormControl>
              <FormDescription>
                <InsertVariables
                  customRef={applicationEmailRef}
                  onVariableClick={handleInsertVariableToSubjectField}
                />
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <Separator />

      <div className="space-y-2">
        <FormLabel className="text-md">
          Interview Completed Message
          <RequiredStar />
        </FormLabel>
        <FormField
          control={control}
          name="afterInterviewSubject"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  ref={(ref) => {
                    field.ref(ref);
                    afterInterviewSubjectInputRef.current = ref;
                  }}
                  onFocus={() => {
                    setFocusedSubjectInput('afterInterviewSubject');
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="afterInterview"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <TextEditor
                  // @ts-expect-error style works
                  // style={{ height: "80%" }}
                  className="h-[300px] pb-20"
                  theme="snow"
                  placeholder="Company and Job Content. ie: Your company's mission, vision, and values. Job description, responsibilities, and requirements."
                  onFocus={() => {
                    if (focusedSubjectInput) {
                      setFocusedSubjectInput(null);
                    }
                  }}
                  {...field}
                  ref={(ref) => {
                    field.ref(ref);
                    // @ts-ignore
                    interviewCompletedEmailRef.current = ref;
                  }}
                />
              </FormControl>
              <FormDescription>
                <InsertVariables
                  customRef={interviewCompletedEmailRef}
                  onVariableClick={handleInsertVariableToSubjectField}
                />
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <Separator />
      <FormField
        control={control}
        name="disqualifiedToggle"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Tabs value={field.value} onValueChange={field.onChange}>
                <div className="flex justify-between items-center w-full">
                  <FormLabel>Disqualified Communication</FormLabel>
                  <TabsList>
                    <TabsTrigger value="disabled">Disabled</TabsTrigger>
                    <TabsTrigger value="enabled">Enabled</TabsTrigger>
                  </TabsList>
                </div>
                <TabsContent value="disabled">
                  <FormDescription>
                    Automatically send a message to disqualified candidates.
                  </FormDescription>
                </TabsContent>

                <TabsContent value="enabled">
                  <div className="space-y-2">
                    <FormField
                      control={control}
                      name="disqualifiedSubject"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Disqualification Subject
                            <RequiredStar />
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              ref={(ref) => {
                                field.ref(ref);
                                disqualifiedSubjectInputRef.current = ref;
                              }}
                              onFocus={() => {
                                setFocusedSubjectInput('disqualifiedSubject');
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="disqualified"
                      render={({ field }) => (
                        <FormItem>
                          {/* <FormLabel>Disqualification Email to be sent<RequiredStar /></FormLabel> */}
                          <FormControl>
                            <TextEditor
                              // @ts-expect-error style works
                              // style={{ height: "80%" }}
                              className="h-[300px] pb-20"
                              theme="snow"
                              placeholder="Disqualification email content. ie: We regret to inform you that you have been disqualified from the application process for {{position_name}}. If you have any questions, please feel free to contact us at {{company_email}}."
                              onFocus={() => {
                                if (focusedSubjectInput) {
                                  setFocusedSubjectInput(null);
                                }
                              }}
                              {...field}
                              value={field.value}
                              ref={(ref) => {
                                field.ref(ref);
                                // @ts-ignore
                                disqualifiedRef.current = ref;
                              }}
                            />
                          </FormControl>
                          <FormDescription>
                            <InsertVariables
                              customRef={disqualifiedRef}
                              onVariableClick={
                                handleInsertVariableToSubjectField
                              }
                            />
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </TabsContent>
              </Tabs>
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
