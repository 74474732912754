import { useRouteContext } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

export function useIntercomMessenger({ customAttributes = {} } = {}) {
  const {
    auth: { user },
  } = useRouteContext({ strict: false });

  const {
    boot,
    // shutdown,
    hardShutdown,
    update,
    // hide,
    show,
    // showMessages,
    // showNewMessage,
    getVisitorId,
    // showConversation,
    showNewMessage,
  } = useIntercom();

  function getUserDetails() {
    let details;
    if (user) {
      details = {
        email: user?.primaryEmailAddress?.emailAddress,
        userId: user?.id,
        name: user?.fullName ?? 'Guest',
      };
    } else {
      details = {
        email: undefined,
        userId: undefined,
        // @ts-ignore
        name: customAttributes?.isCandidate ? 'Candidate' : 'Guest',
      };
    }

    // console.log("user", { user, details });
    return details;
  }

  useEffect(() => {
    const details = getUserDetails();

    update(details);
  }, [user, getUserDetails]);

  const openChat = () => {
    // check if intercom booted
    if (!getVisitorId()) {
      boot({
        email: user?.primaryEmailAddress?.emailAddress,
        userId: user?.id,
        name: user?.fullName ?? 'Guest',
      });
    }

    show();
  };

  const closeChat = () => {
    // @ts-ignore
    hardShutdown();
  };

  return { openChat, closeChat, showNewMessage };
}
