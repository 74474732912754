import ReactQuill, { ReactQuillProps } from 'react-quill';
import { forwardRef } from 'react';
import 'react-quill/dist/quill.snow.css';

type Props = Pick<
  ReactQuillProps,
  'value' | 'onChange' | 'placeholder' | 'theme'
>;

const toolBarOptions = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

const TextEditor = forwardRef(function TextEditor(
  props: Props,
  ref: React.Ref<ReactQuill>
) {
  return (
    <ReactQuill {...props} modules={toolBarOptions} ref={ref}></ReactQuill>
  );
});

export { TextEditor };
