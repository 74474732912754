import { Dialog } from '@/components/ui/dialog';

import { InviteForm } from './InviteForm';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { PlusIcon } from 'lucide-react';
import { DialogContent, DialogTrigger } from '@/components/ui/dialog';
export function InviteDialog() {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {/* <Button variant="outline">Edit Profile</Button> */}
        <Button>
          <PlusIcon className="size-4" />
          <span className="ml-2">Add People</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="p-2">
        <InviteForm onSubmit={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  );
}
