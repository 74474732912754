import { cn } from '@/lib/utils';
import './layers.css';

export function LayersLoader({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        'absolute inset-0 z-20 flex items-center justify-center bg-gray-200',
        className
      )}
    >
      <div className="layers" />
    </div>
  );
}
