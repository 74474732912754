import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button } from '@/components/ui/button';

export const AlertModalTypes = {
  DANGER: 'DANGER',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

const AlertModelColors = {
  [AlertModalTypes.DANGER]: {
    icon: 'text-red-600',
    iconFrame: 'bg-red-100',
    button: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
  },
  [AlertModalTypes.WARNING]: {
    icon: 'text-yellow-600',
    iconFrame: 'bg-yellow-100',
    button: 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500',
  },
  [AlertModalTypes.INFO]: {
    icon: 'text-green-600',
    iconFrame: 'bg-secondary-100',
    button: 'bg-black hover:bg-secondary-700 focus:ring-secondary-500',
  },
};

type TProps = {
  title: string;
  body: string;
  confirmText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onProceed: () => void;
  open: boolean;
  onClose: () => void;
  type?: string;
  isConfirmLoading?: boolean;
};
export default function AlertModal({
  open,
  onClose,
  title,
  body,
  confirmText,
  onProceed,
  type = AlertModalTypes.DANGER,
  isConfirmLoading,
}: TProps) {
  const cancelButtonRef = useRef(null);

  function onCancelClick() {
    onClose();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onProceedClick() {
    onProceed();
    onClose();
  }

  const colorScheme = AlertModelColors[type];
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 ${colorScheme.iconFrame}`}
                  >
                    <ExclamationTriangleIcon
                      className={`h-6 w-6 ${colorScheme.icon}`}
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 prose prose-slate whitespace-pre-line">
                        {body}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex mt-5 md:mt-4 md:flex-row-reverse gap-2">
                  <Button
                    variant="destructive"
                    type="button"
                    className={colorScheme.button}
                    onClick={onProceedClick}
                    isLoading={isConfirmLoading}
                    disabled={isConfirmLoading}
                  >
                    {confirmText}
                  </Button>
                  <Button
                    variant="outline"
                    type="button"
                    onClick={onCancelClick}
                    ref={cancelButtonRef}
                    disabled={isConfirmLoading}
                  >
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
