import { Button } from '@/components/ui/button';

import { Form } from '@/components/ui/form';
import { toast } from 'sonner';
import { mergeDataWithDefaults } from './form-defaults';
import {
  TPosition,
  useCommunicationForm,
  useUpdateCommunicationFormMutation,
} from '@/fetchers/usePosition';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useIsMutating } from '@tanstack/react-query';
import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';
import {
  handleMapFormValuesToData,
  PositionCommunicationFormValues,
  usePositionCommunicationForm,
} from '@/lib/usePositionCommunicationForm';
import { PositionCommunicationForm } from './communication-form';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useEffect } from 'react';

export function CommunicationFormContainer({
  position,
}: {
  position?: TPosition;
}) {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false });
  const { creating } = usePositionManageRouteSearch();
  const updateCommunicationForm = useUpdateCommunicationFormMutation();
  const navigate = useNavigate();
  const isMutating = useIsMutating();
  const { data: communicationFormData } = useCommunicationForm({
    slug,
  });

  const { selectedOrganization } = useUserDetailStore();

  const form = usePositionCommunicationForm();

  const { handleSubmit, reset, watch } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (communicationFormData) {
      reset({
        ...mergeDataWithDefaults(communicationFormData),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationFormData, position, selectedOrganization?.name]);

  async function onSubmit(values: PositionCommunicationFormValues) {
    const { error } = await updateCommunicationForm({
      data: handleMapFormValuesToData(values),
      organizationId,
      slug,
    });

    if (error) {
      toast.error('Error updating communication form');
      return;
    }

    toast.success('Communication form updated');
    if (creating) {
      navigate({
        to: '/organizations/$organizationId/positions/manage/$slug/$step',
        params: {
          organizationId: String(organizationId),
          slug,
          step: 'ai-config',
        },
        search: {
          creating: true,
        },
      });
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <PositionCommunicationForm />
        {isFormInitialized && (
          <Button
            isLoading={isMutating > 0}
            className="float-right"
            type="submit"
          >
            {creating ? 'Next' : 'Update'}
          </Button>
        )}
      </form>
    </Form>
  );
}
