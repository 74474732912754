import { MessageCircle, Star, Clock } from 'lucide-react';

import { Card, CardHeader, CardContent } from '@/components/ui/card';

import { UpdatePhoneDialog } from './update-phone-dialog';
import { cn } from '@/lib/utils';

export function InterviewGuideline({
  phone,
  confirmed,
}: {
  phone: string;
  confirmed: boolean;
}) {
  return (
    <div className="max-w-2xl mx-auto p-4">
      <Card className="mb-4">
        <CardHeader>
          <h2 className="text-2xl font-bold text-center">
            Your Interview Guidelines
          </h2>
        </CardHeader>
        <CardContent>
          <p className="text-center mb-4">
            Welcome to your AI interview experience! This is your chance to
            shine beyond your resume. Our AI is designed to give you a fair,
            comprehensive opportunity to showcase why you're the perfect fit for
            this role. Take your time, be yourself, and remember: every response
            helps us understand your unique potential. Your voice matters, and
            we're excited to hear what you have to say!
          </p>
          <div className="flex justify-around text-center">
            <div>
              <MessageCircle size={32} className="mx-auto mb-2 text-blue-500" />
              <p className="text-sm">Share Your Story</p>
            </div>
            <div>
              <Star size={32} className="mx-auto mb-2 text-yellow-500" />
              <p className="text-sm">Highlight Your Skills</p>
            </div>
            <div>
              <Clock size={32} className="mx-auto mb-2 text-green-500" />
              <p className="text-sm">Take Your Time</p>
            </div>
          </div>
        </CardContent>
      </Card>

      <div className="space-y-8 mt-10 mb-6">
        <div
          className={cn(
            'flex flex-col justify-center items-center my-4',
            confirmed && 'hidden'
          )}
        >
          <p className="text-sm font-semibold w-full text-center">
            WRONG NUMBER?
          </p>
          <UpdatePhoneDialog phone={phone} />
        </div>

        <div className="max-w-xl mx-auto px-6 max-w-sm  text-center">
          <p className="text-base">
            This call may be recorded for quality and training purposes
          </p>
          <p className="text-muted-foreground text-base">
            By clicking "Interview Now" you accept the
            <br />{' '}
            <span>
              <a
                className="underline"
                href="https://app.veton.ai/terms"
                target="__black"
              >
                Terms and Conditions
              </a>
            </span>{' '}
            and{' '}
            <span>
              <a
                className="underline"
                href="https://app.veton.ai/privacy"
                target="__black"
              >
                Privacy Policy
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
