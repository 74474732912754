import { useForm, UseFormProps } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const positionApplicatonFormSchema = z.object({
  name: z.enum(['required']),
  email: z.enum(['required']),
  phone: z.enum(['required']),
  address: z.enum(['required', 'optional', 'disabled']),
  linkedinProfile: z.enum(['required', 'optional', 'disabled']),
  desiredSalary: z.enum(['required', 'optional', 'disabled']),
  resume: z.enum(['required']),
  education: z.enum(['required', 'optional', 'disabled']),
  coverLetter: z.enum(['required', 'optional', 'disabled']),
  additionalInfo: z.enum(['required', 'optional', 'disabled']),
  isInitialized: z.boolean().default(false).optional(),
});

export type PositionApplicationFormValues = z.infer<
  typeof positionApplicatonFormSchema
>;

export const usePositionApplicationForm = (
  options?: UseFormProps<PositionApplicationFormValues>
) => {
  return useForm<PositionApplicationFormValues>({
    resolver: zodResolver(positionApplicatonFormSchema),
    ...options,
  });
};
