import { Separator } from '@/components/ui/separator';
import { HiringTeamFormContainer } from './hiring-team-form-container';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';

export default function HiringTeamFormView() {
  const { showNewMessage } = useIntercomMessenger();

  return (
    <>
      <div>
        <h3 className="text-lg font-medium">Hiring Team</h3>
        <p className="text-sm text-muted-foreground">
          Manage the hiring team involved in this position.{' '}
          <button
            className="text-blue-500 hover:underline"
            onClick={() => {
              showNewMessage(
                'Hi! I would like to learn more about the hiring team feature.'
              );
            }}
          >
            Learn More
          </button>
        </p>
      </div>
      <Separator />
      <HiringTeamFormContainer />
    </>
  );
}
