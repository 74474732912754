type TProps = {
  recording: Blob;
  presignedUrl: string;
  mediaType: 'audio/webm' | 'video/webm';
};
export const uploadRecording = async ({
  recording,
  presignedUrl,
  mediaType,
}: TProps) => {
  try {
    console.log('uploadRecording');

    // Upload the file using the pre-signed URL
    const uploadResponse = await fetch(presignedUrl, {
      method: 'PUT', // PUT method is usually used for S3 pre-signed PUT URLs
      body: recording, // File needs to be uploaded
      headers: {
        'Content-Type': mediaType, // Or any other content type depending on the file
      },
    });

    if (uploadResponse.ok) {
      console.log('File successfully uploaded');
    } else {
      console.error('Failed to upload file');
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};
