import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as PositionDefaultsRoute } from '@/routes/organizations.$organizationId.company.position-defaults';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { useIsMutating } from '@tanstack/react-query';
import {
  usePositionAiConfigForm,
  PositionAIConfigFormValues,
  handleMapAIConfigDataToFormValues,
} from '@/lib/usePositionAiConfigForm';
import { PositionAIConfigForm } from '@/components/core/position/ai-config/aiconfig-form';
import {
  useGetOrganizationAIConfig,
  useUpdateOrganizationAIConfigMutation,
} from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { toast } from 'sonner';

export const Route = createRoute({
  getParentRoute: () => PositionDefaultsRoute,
  path: '/ai-config',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsAIConfigPage,
});

function CompanyPositionDefaultsAIConfigPage() {
  const { organizationId } = Route.useParams();
  const parsedOrganizationId = Number(organizationId);
  const isMutating = useIsMutating();
  const form = usePositionAiConfigForm();
  const { data: aiConfigForm } = useGetOrganizationAIConfig({
    organizationId: parsedOrganizationId,
  });
  const updateOrganizationAIConfigMutation =
    useUpdateOrganizationAIConfigMutation();
  const { handleSubmit, reset, watch } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (aiConfigForm) {
      reset({
        ...handleMapAIConfigDataToFormValues(aiConfigForm),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiConfigForm]);

  const onSubmit = async (data: PositionAIConfigFormValues) => {
    const additionalInfo = data?.additionalQuestions
      ?.map((question) => question.value)
      .filter(Boolean);
    const hasAdditionalInfo = additionalInfo && additionalInfo.length > 0;
    const restrictQuestions =
      hasAdditionalInfo && data.restrictQuestions === 'enabled';
    const res = await updateOrganizationAIConfigMutation({
      data: {
        voiceModel: data.voiceModel,
        additionalQuestions: additionalInfo,
        restrictQuestions,
      },
      organizationId: parsedOrganizationId,
    });
    if (res.data) {
      toast.success('AI config updated successfully!');
    } else {
      toast.error(res.error);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 w-full">
        <PositionAIConfigForm />
        {isFormInitialized && (
          <Button
            isLoading={isMutating > 0}
            className="float-right"
            type="submit"
          >
            Save
          </Button>
        )}
      </form>
    </Form>
  );
}
