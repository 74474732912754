import { Button } from '@/components/ui/button';

import { Form } from '@/components/ui/form';
import { toast } from 'sonner';
import {
  useApplicationForm,
  useUpdateApplicationFormMutation,
} from '@/fetchers/usePosition';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useIsMutating } from '@tanstack/react-query';
import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';
import {
  usePositionApplicationForm,
  PositionApplicationFormValues,
} from '@/lib/usePositionApplicationForm';
import { PositionApplicationForm } from './application-form';
import { useEffect } from 'react';

export function ApplicationFormContainer() {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false });
  const updateApplicationForm = useUpdateApplicationFormMutation();
  const { creating } = usePositionManageRouteSearch();
  const navigate = useNavigate();
  const isMutating = useIsMutating();
  const { data: positionApplicationFormData } = useApplicationForm({
    slug,
  });

  const form = usePositionApplicationForm({
    defaultValues: positionApplicationFormData,
  });

  const { handleSubmit, reset, watch } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (positionApplicationFormData) {
      reset({
        ...positionApplicationFormData,
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionApplicationFormData]);

  async function onSubmit(data: PositionApplicationFormValues) {
    const { error } = await updateApplicationForm({
      //@ts-ignore
      data,
      slug,
      organizationId,
    });
    if (error) {
      toast.error('Error updating application form');
      return;
    }
    toast.success('Application form updated');

    if (creating) {
      navigate({
        to: '/organizations/$organizationId/positions/manage/$slug/$step',
        params: {
          organizationId: String(organizationId),
          slug: slug,
          step: 'communication',
        },
        search: {
          creating: true,
        },
      });
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <PositionApplicationForm />
        {isFormInitialized && (
          <Button
            isLoading={isMutating > 0}
            className="float-right"
            type="submit"
          >
            {creating ? 'Next' : 'Update'}
          </Button>
        )}
      </form>
    </Form>
  );
}
