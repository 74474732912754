import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export function useImportApplicant() {
  const downloadTemplate = () => {
    const templateData = [
      {
        'Candidate Name': '',
        'Candidate Phone': '',
        'Candidate Email': '',
        'Candidate Resume Url': '',
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(templateData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');

    // Set column widths
    worksheet['!cols'] = [
      { wch: 20 }, // Candidate Name
      { wch: 15 }, // Candidate Phone
      { wch: 25 }, // Candidate email
      { wch: 30 }, // Candidate Resume Url
    ];

    // Add some styling
    const range = XLSX.utils.decode_range(worksheet['!ref']!);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellRef]) continue;
        worksheet[cellRef].s = {
          font: { bold: true },
          alignment: { horizontal: 'center', vertical: 'center' },
        };
      }
    }

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'candidate_import_template.xlsx');
  };

  return { downloadTemplate };
}
