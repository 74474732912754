import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import {
  useAIConfigForm,
  useUpdateAIConfigFormMutation,
} from '@/fetchers/usePosition';
import { useIsMutating } from '@tanstack/react-query';
import { useNavigate, useParams } from '@tanstack/react-router';

import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';
import {
  handleMapAIConfigDataToFormValues,
  PositionAIConfigFormValues,
  usePositionAiConfigForm,
} from '@/lib/usePositionAiConfigForm';
import { PositionAIConfigForm } from './aiconfig-form';
import { useEffect } from 'react';

export function PositionAIConfigFormContainer() {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false });
  const { creating } = usePositionManageRouteSearch();
  const navigate = useNavigate();
  const updateAiConfigFormMutation = useUpdateAIConfigFormMutation();
  const { data: positionAiConfigFormData } = useAIConfigForm({
    slug,
  });

  const isMutating = useIsMutating();

  const form = usePositionAiConfigForm();
  const { handleSubmit, reset, watch } = form;
  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (positionAiConfigFormData) {
      reset({
        ...handleMapAIConfigDataToFormValues(positionAiConfigFormData),
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionAiConfigFormData]);

  async function onSubmit(data: PositionAIConfigFormValues) {
    const additionalInfo = data?.additionalQuestions
      ?.map((question) => question.value)
      .filter(Boolean);
    if (!slug) {
      return toast.error('Position is missing');
    }
    const hasAdditionalInfo = additionalInfo && additionalInfo.length > 0;
    const restrictQuestions =
      hasAdditionalInfo && data.restrictQuestions === 'enabled';
    const { error } = await updateAiConfigFormMutation({
      slug,
      organizationId,
      data: {
        voiceModel: data.voiceModel,
        additionalQuestions: additionalInfo,
        restrictQuestions,
      },
    });

    if (error) {
      toast.error('Error updating AI config form');
      return;
    }

    toast.success('AI config form updated');

    if (creating) {
      navigate({
        to: '/organizations/$organizationId/positions/manage/$slug/$step',
        params: {
          organizationId: String(organizationId),
          slug,
          step: 'hiring-team',
        },
        search: {
          creating: true,
        },
      });
    }
  }

  return (
    <>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <PositionAIConfigForm />
          {isFormInitialized && (
            <Button
              isLoading={isMutating > 0}
              type="submit"
              className="float-right"
            >
              {creating ? 'Next' : 'Update'}
            </Button>
          )}
        </form>
      </Form>
    </>
  );
}
