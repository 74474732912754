import { Button } from '@/components/ui/button';

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

import { useState } from 'react';
import { CreateParentOrganizationForm } from './create-parent-organization-form';
import { useOrganizationStore } from '@/fetchers/useOrganization';
import { Settings } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';

export function UpdateParentOrganizationPopup() {
  const [open, setOpen] = useState(false);

  const { selectedParentOrganization } = useOrganizationStore();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip delayDuration={0}>
        <DialogTrigger asChild>
          <TooltipTrigger asChild>
            <Button variant={'ghost'} className="p-1 h-fit">
              <Settings className="md:size-4" />
              <TooltipContent side="bottom">
                Organization Settings
              </TooltipContent>
            </Button>
          </TooltipTrigger>
        </DialogTrigger>
      </Tooltip>
      <DialogContent>
        <CreateParentOrganizationForm
          layoutClasses="border-none"
          organization={selectedParentOrganization}
        />
      </DialogContent>
    </Dialog>
  );
}
