import { Separator } from '@/components/ui/separator';
import { CommunicationFormContainer } from './communication-form-container';
import { TPosition } from '@/fetchers/usePosition';

export default function CommunicationFormView({
  position,
}: {
  position?: TPosition;
}) {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium">Communication method</h3>
        <p className="text-sm text-muted-foreground">
          Customize the communication method for this position.
        </p>
      </div>
      <Separator />
      <CommunicationFormContainer position={position} />
    </>
  );
}
