import AuthenticationPage from '@/pages/authentication/page';
import { CreateParentOrganizationForm } from './create-parent-organization-form';

export function ParentOgranizationCreationView() {
  return (
    <AuthenticationPage>
      <CreateParentOrganizationForm />
    </AuthenticationPage>
  );
}
