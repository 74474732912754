import { useCallback, useEffect } from 'react';
import {
  ERROR_MESSAGES,
  useRecordWebcam,
  getDevices,
  ByType,
} from 'react-record-webcam';
import { create } from 'zustand';

export const useWebcamRecorderStore = create<{
  devicesByType: ByType;
  setDevicesByType: (value: ByType) => void;

  userSetCamereOn: boolean;
  setUserSetCameraOn: (value: boolean) => void;

  videoDeviceId: string;
  setVideoDeviceId: (value: string) => void;

  audioDeviceId: string;
  setAudioDeviceId: (value: string) => void;
}>()((set) => ({
  devicesByType: {
    video: [],
    audio: [],
  },
  setDevicesByType: (value) => set({ devicesByType: value }),

  userSetCamereOn: true,
  setUserSetCameraOn: (value) => set({ userSetCamereOn: value }),

  videoDeviceId: '',
  setVideoDeviceId: (value) => set({ videoDeviceId: value }),

  audioDeviceId: '',
  setAudioDeviceId: (value) => set({ audioDeviceId: value }),
}));

export const useWebcamRecorder = () => {
  const {
    activeRecordings,
    // cancelRecording,
    createRecording,
    devicesById,
    devicesByType: devicesByTypeWebRecorder,
    // download,
    openCamera,
    // pauseRecording,
    // resumeRecording,
    startRecording,
    stopRecording,
    // applyConstraints,
    clearAllRecordings,
    closeCamera,
    download,
    errorMessage,
  } = useRecordWebcam();
  const noCameraError =
    errorMessage === ERROR_MESSAGES.NO_USER_PERMISSION ||
    errorMessage === ERROR_MESSAGES.CODEC_NOT_SUPPORTED;

  // const [devicesByTypeState, setDevicesByTypeState] = useState(devicesByTypeWebRecorder || {});

  const {
    devicesByType,
    setDevicesByType,
    userSetCamereOn,
    setUserSetCameraOn,
    videoDeviceId,
    setVideoDeviceId,
    audioDeviceId,
    setAudioDeviceId,
  } = useWebcamRecorderStore((state) => state);

  useEffect(() => {
    if (
      devicesByType &&
      (devicesByType?.audio?.length > 0 || devicesByType?.video?.length > 0)
    )
      return;
    if (!devicesByTypeWebRecorder) return;
    setDevicesByType(devicesByTypeWebRecorder || {});
  }, [devicesByTypeWebRecorder, devicesByType]);

  const quickDemo = async () => {
    try {
      // const recording = await createRecording();
      // if (!recording) return;
      // await openCamera(recording.id);
      // let recording = activeRecordings?.[0]
      // if (!recording) {
      //   recording = await startCamera()
      // }
      const recording = activeRecordings?.[0];
      await startRecording(recording.id);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await stopRecording(recording.id);
      await closeCamera(recording.id);
      download(recording.id);
      await clearAllRecordings();
    } catch (error) {
      console.log({ error });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = async (deviceId: any) => {
    if (devicesById?.[deviceId]?.type === 'videoinput') {
      setVideoDeviceId(deviceId);
    }
    if (devicesById?.[deviceId]?.type === 'audioinput') {
      setAudioDeviceId(deviceId);
    }
  };

  const init = useCallback(() => {
    if (!userSetCamereOn) return;
    if (!devicesById) return;
    if (audioDeviceId || videoDeviceId) return;

    setVideoDeviceId(
      Object.keys(devicesById).find(
        (key) => devicesById[key].type === 'videoinput'
      ) || ''
    );
    setAudioDeviceId(
      Object.keys(devicesById).find(
        (key) => devicesById[key].type === 'audioinput'
      ) || ''
    );

    const recording = activeRecordings?.[0];
    if (!recording) {
      startCamera();
    }
  }, [
    devicesById,
    activeRecordings,
    userSetCamereOn,
    audioDeviceId,
    videoDeviceId,
  ]);

  async function handleDeviceChange() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const devices2 = await getDevices();
    setDevicesByType(devices2?.devicesByType || {});

    if (!devices) return;

    const videoDevice = devices.find((device) => device.kind === 'videoinput');
    handleSelect(videoDevice?.deviceId);

    const audioDevice = devices.find((device) => device.kind === 'audioinput');
    handleSelect(audioDevice?.deviceId);
  }

  useEffect(() => {
    init();
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      );
    };
  }, [init]);

  useEffect(() => {
    if (noCameraError) {
      setUserSetCameraOn(false);
    }
  }, [noCameraError]);

  const startCamera = async () => {
    const recording = await createRecording(videoDeviceId, audioDeviceId);
    if (recording) await openCamera(recording.id);

    return recording;
  };

  return {
    videoDeviceId,
    audioDeviceId,
    setAudioDeviceId,
    setVideoDeviceId,
    devicesByType,
    activeRecordings,
    start: async () => {
      const recording = await startCamera();
      console.log('webcamrecorder started => ', recording?.id);
      if (!recording) return;
      return recording;
    },
    record: async () => {
      try {
        let recording = activeRecordings?.[0];
        if (!recording) {
          console.log('No active recording, starting camera');
          // @ts-ignore
          recording = await startCamera();
        }

        if (recording) {
          await startRecording(recording.id);
        } else {
          console.error('Failed to create or find an active recording');
        }
      } catch (error) {
        console.error('Error starting recording:', error);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stop: async (cb: any) => {
      const recording = activeRecordings?.[0];
      if (!recording?.id) {
        console.log('No active recording');
        return;
      }
      await stopRecording(recording.id);
      await cb(recording.blob);
      // Don't clear recordings here
    },
    turnonCamera: async () => {
      const recording = activeRecordings?.[0];
      if (recording) await openCamera(recording.id);
    },
    shutdownCamera: async () => {
      const recording = activeRecordings?.[0];
      await closeCamera(recording?.id);
    },
    quickDemo,
    userSetCamereOn,
    setUserSetCameraOn,
    noCameraError,
    handleSelect,
    clearAllRecordings,
  };
};
