import { ApplicationFormValues } from '@/components/core/careers/single/application-form';
import { fetcher } from '@/lib/fetcher';
import {
  convertToCamelCase,
  convertToSnakeCase,
  handleQueryError,
  parseAPIError,
  uuid,
} from '@/lib/utils';
import { useAuth } from '@clerk/clerk-react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { toast } from 'sonner';

export type TApplication = {
  name: string;
  email: string;
  phone: string;
  address?: string | null;
  linkedinProfile?: string | null;
  desiredSalary?: string | null;
  education?: string | null;
  additionalInfo?: string | null;
  resume: File;
  coverLetter?: File | null;
  archived?: boolean;
};

export function useApplyMutation() {
  const mutation = useMutation({
    mutationFn: async ({
      data,
      slug,
    }: {
      data: Partial<ApplicationFormValues>;
      slug: string;
    }) => {
      const formData = new FormData();
      const { resume, coverLetter, ...rest } = data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const snakePayload: any = convertToSnakeCase(rest);

      if (resume) {
        formData.append('resume', resume);
      }
      if (coverLetter) {
        formData.append('cover_letter', coverLetter);
      }

      Object.keys(snakePayload).forEach((key) => {
        formData.append(key, snakePayload[key]);
      });

      return await fetcher
        .post(`/applications/positions/${slug}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((r) => ({
          data: convertToCamelCase(r.data) as unknown as TApplication,
          error: null,
        }))
        .catch((e) => {
          toast.error('Something went wrong!', {
            description: parseAPIError(e),
            position: 'top-right',
          });
          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
  });

  return mutation.mutateAsync;
}

// {
//   "interviews": [
//     {
//       "id": 12,
//       "final_verdict": "not_fit",
//       "assistant_type": "mock-interview",
//       "started_at": null,
//       "completed_at": "2024-03-23T13:30:53.151Z",
//       "analysis_summary": "The candidate' ..."
//     }
//   ],
//   "id": 17,
//   "name": "Alex Vi",
//   "email": "ali@veton.ai",
//   "phone": "+12539731892"
// }

type ApplicantInterview = {
  id: number;
  finalVerdict: string;
  assistantType: string;
  startedAt: string | null;
  completedAt: string | null;
  analysisSummary: string | null;
};

export type Application = {
  id: number;
  name: string;
  email: string;
  phone: string;
  interviews: ApplicantInterview[];
  user: {
    id: string;
    username: string;
  };
  resumeAnalysisVerdict: string | null;
  resumeAnalysis: string | null;
  archived?: boolean;
};

export function useGetApplications({ slug }: { slug: string }) {
  const { getToken } = useAuth();

  const query = useQuery({
    queryKey: ['applications', 'admin', slug],
    queryFn: async () => {
      return await fetcher
        .get(`/admin/applications/positions/${slug}`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then((r) => convertToCamelCase(r.data) as unknown as Application[]);
    },
    enabled: !!slug,
  });

  return query;
}

export function useUpdateApplicationMutation({ slug }: { slug: string }) {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['applications', 'admin', slug],
        (old: Application[]) => {
          return old.map((application: Application) => {
            if (application.id !== data?.id) return application;
            return {
              ...application,
              ...data,
            };
          });
        }
      );
    },
    mutationFn: async ({
      data,
      applicationId,
    }: {
      data: { archived?: boolean; deleted?: boolean };
      applicationId: number;
    }) => {
      return await fetcher
        .patch(
          `/admin/applications/${applicationId}`,
          convertToSnakeCase(data),
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => convertToCamelCase(r.data) as unknown as Application)
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to update application');
        });
    },
  });

  return mutation.mutateAsync;
}

export type ApplicationDetailInterview = {
  id: number;
  finalVerdict: string;
  assistantType: string;
  startedAt: string | null;
  completedAt: string | null;
  analysisSummary: string | null;
  analysis: string | null;
  audioRecordingUrl: string | null;
  videoRecordingUrl: string | null;
  interviewUrl: string | null;
  additionalQuestionsAndAnswers?: {
    question: string;
    answer: string;
  }[];
};
export type ApplicationDetail = {
  id: number;
  name: string;
  email: string;
  phone: string;
  linkedinProfile: string | null;
  desiredSalary: string | null;
  education: string | null;
  additionalInfo: string | null;
  createdAt: string;
  resumeUrl: string;
  coverLetterUrl: string | null;
  salaryType: string | null;
  salaryCurrency: string | null;
  interviews: ApplicationDetailInterview[];
  userAvatar: string | null;
  resumeAnalysis: string | null;
  resumeAnalysisVerdict: string | null;
  position: {
    slug: number;
    title: string;
  };
};

export function useGetApplication({
  applicationId,
}: {
  applicationId: number;
}) {
  const { getToken } = useAuth();

  const query = useQuery({
    queryKey: ['application', 'admin', applicationId],
    queryFn: async () => {
      return await fetcher
        .get(`/admin/applications/${applicationId}`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then(
          (r) => convertToCamelCase(r.data) as unknown as ApplicationDetail
        );
    },
    enabled: !!applicationId,
  });

  return query;
}

export function useGetApplicationMessages({
  applicationId,
  enabled,
}: {
  applicationId: number;
  enabled?: boolean;
}) {
  const { getToken } = useAuth();

  const query = useQuery({
    queryKey: ['application', 'admin', 'messages', applicationId],
    queryFn: async () => {
      return await fetcher
        .get(`/admin/applications/${applicationId}/messages`, {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        })
        .then(
          (r) =>
            convertToCamelCase(r.data) as unknown as {
              id: string;
              content: string;
              role: string;
              createdAt: string;
            }[]
        );
    },
    enabled: !!applicationId && !!enabled,
  });

  return query;
}

export function useGetMessageResponseRecording({
  messageId,
  applicationId,
}: {
  messageId: string;
  applicationId: number;
}) {
  const { getToken } = useAuth();

  const query = useQuery({
    queryKey: ['message', 'admin', 'recording', applicationId, messageId],
    queryFn: async () => {
      return await fetcher
        .get(
          `/admin/applications/${applicationId}/messages/${messageId}/recording`,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then((r) => convertToCamelCase(r.data) as unknown as { url: string })
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to get recording');
        });
    },
    enabled: !!messageId,
  });

  return query;
}

export function useSendReminderMutation() {
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({
      positionSlug,
      candidates,
    }: {
      positionSlug: string;
      candidates: string;
    }) => {
      return await fetcher
        .post(
          `/admin/applications/interviews/positions/${positionSlug}/remind`,
          { candidates },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(
          (r) => convertToCamelCase(r.data) as unknown as { message: string }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to send reminder');
        });
    },
  });

  return mutation.mutateAsync;
}

export function useGetWebGeneralApplication({
  enabled,
  storyOptions,
}: {
  enabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storyOptions?: any;
}) {
  const query = useQuery({
    queryKey: ['web', 'general', 'application', JSON.stringify(storyOptions)],
    queryFn: async () => {
      return await fetcher
        .get(
          `/applications/interviews/general_application/web?story_options=${JSON.stringify(storyOptions)}`,
          {}
        )
        .then(
          (r) =>
            convertToCamelCase(r.data) as unknown as {
              assistantType: string;
              openaiAssistantId: string;
              openaiThreadId: string;
              voiceModel: string;
            }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to get recording');
        });
    },
    enabled: !!enabled,
  });

  return query;
}

export function useSendBulkInterviewMutation() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  // @ts-ignore
  const { slug, organizationId } = useParams({ strict: false });
  const mutation = useMutation({
    onMutate: async ({ applicationIds }: { applicationIds?: number[] }) => {
      const previousTodos = queryClient.getQueryData([
        'applications',
        'admin',
        slug,
      ]);

      queryClient.setQueryData(
        ['applications', 'admin', slug],
        (old: Application[]) => {
          return old.map((application: Application) => {
            if (!applicationIds?.includes(application.id)) return application;
            return {
              ...application,
              interviews: [
                ...(application.interviews || []),
                {
                  id: uuid(),
                },
              ],
            };
          });
        }
      );

      applicationIds?.forEach((applicationId) => {
        queryClient.setQueryData(
          ['application', 'admin', applicationId],
          (old: Application) => {
            if (!old) return;
            return {
              ...old,
              interviews: [
                ...(old.interviews || []),
                {
                  id: uuid(),
                },
              ],
            };
          }
        );
      });

      return { previousTodos };
    },
    mutationFn: async ({ applicationIds }: { applicationIds?: number[] }) => {
      if (!applicationIds || applicationIds?.length === 0) {
        toast('No candidate selected');

        return;
      }

      return await fetcher
        .post(
          `/admin/applications/bulk/interviews`,
          {
            application_ids: applicationIds,
            organization_id: Number(organizationId),
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(
          (r) => convertToCamelCase(r.data) as unknown as { message: string }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to send reminder');
        });
    },
  });

  return mutation.mutateAsync;
}

export function useSendInterviewMutation() {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  // @ts-ignore
  const { slug } = useParams({ strict: false });
  const mutation = useMutation({
    onMutate: async ({ applicationId }: { applicationId?: number }) => {
      const previousTodos = queryClient.getQueryData([
        'applications',
        'admin',
        slug,
      ]);

      // TODO: Merge these 2 queries somehow
      queryClient.setQueryData(
        ['applications', 'admin', slug],
        (old: Application[]) => {
          return old.map((application: Application) => {
            if (application.id !== applicationId) return application;
            return {
              ...application,
              interviews: [
                ...(application.interviews || []),
                {
                  id: uuid(),
                },
              ],
            };
          });
        }
      );

      queryClient.setQueryData(
        ['application', 'admin', applicationId],
        (old: Application) => {
          if (!old) return;
          return {
            ...old,
            interviews: [
              ...(old.interviews || []),
              {
                id: uuid(),
              },
            ],
          };
        }
      );

      return { previousTodos };
    },
    mutationFn: async ({ applicationId }: { applicationId?: number }) => {
      if (!applicationId) {
        return { message: 'No application id' };
      }
      return await fetcher
        .post(
          `/admin/applications/${applicationId}/interviews`,
          {},
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(
          (r) => convertToCamelCase(r.data) as unknown as { message: string }
        )
        .catch((error) => {
          handleQueryError(error);
          throw new Error('Failed to send reminder');
        });
    },
  });

  return mutation.mutateAsync;
}

export function useInviteCandidate() {
  const { getToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({
      email,
      message,
      subject,
      organizationId,
      positionSlug,
    }: {
      email: string;
      message?: string;
      subject: string;
      organizationId: number;
      positionSlug: string;
    }) => {
      return fetcher
        .post(
          `/admin/applications/invite`,
          {
            email,
            message,
            subject,
            organization_id: organizationId,
            position_slug: positionSlug,
          },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(() => {
          return 'ok';
        })
        .catch((error) => {
          handleQueryError(error);
          throw new Error(error);
        });
    },
  });

  return mutation.mutateAsync;
}

export function useBulkUploadApplicants({ slug }: { slug: string }) {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['applications', 'admin', slug],
      });
    },
    mutationFn: async ({
      file,
      slug,
      organizationId,
    }: {
      file: File;
      slug: string;
      organizationId: number;
    }) => {
      const formData = new FormData();

      if (file) {
        formData.append('file', file);
      }

      return await fetcher
        .post(
          `/admin/applications/positions/${slug}/bulk-upload?organization_id=${organizationId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        .then(() => {
          toast('Applicants uploaded successfully');
        })
        .catch((e) => {
          toast.error('Something went wrong!', {
            description: parseAPIError(e),
            position: 'top-right',
          });
          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
  });

  return mutation.mutateAsync;
}

export function useBulkUploadResume() {
  const { getToken } = useAuth();
  // const queryClient = useQueryClient();

  const mutation = useMutation({
    onSuccess: () => {
      // queryClient.invalidateQueries({
      //   queryKey: ["applications", "admin", slug],
      // });
    },
    mutationFn: async ({
      files,
      slug,
      organizationId,
    }: {
      files: FileList;
      slug: string;
      organizationId: number;
    }) => {
      const formData = new FormData();

      if (files) {
        Array.from(files).forEach((file) => {
          formData.append('files', file);
        });
      }

      return await fetcher
        .post(
          `/admin/applications/positions/${slug}/bulk-upload-resumes?organization_id=${organizationId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        )
        // .then(() => {
        //   toast("Applicants uploaded successfully")
        // })
        .catch((e) => {
          toast.error('Something went wrong!', {
            description: parseAPIError(e),
            position: 'top-right',
          });
          return {
            data: null,
            error: parseAPIError(e),
          };
        });
    },
  });

  return mutation.mutateAsync;
}
