import { Loader } from '@/components/core/loader';
import { UserProfile } from '@clerk/clerk-react';
import { createFileRoute, ErrorComponent } from '@tanstack/react-router';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { Layout } from '@/components/core/dashboard/layout';

export const Route = createFileRoute('/user/profile')({
  errorComponent: ({ error }) => {
    console.log('error @UserProfilePage page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: UserProfilePage,
});

function UserProfilePage() {
  return (
    <Layout>
      <Breadcrumb
        items={[
          {
            title: 'Profile',
          },
        ]}
      />
      <div className="flex flex-col h-full">
        <UserProfile
          appearance={{
            elements: {
              breadcrumbs: {
                display: 'none',
              },
              rootBox: {
                height: '100%',
                width: '100%',
              },
              card: {
                width: '100%',
                boxShadow: 'none',
              },
            },
          }}
        />
      </div>
    </Layout>
  );
}
