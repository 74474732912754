import { Button } from '@/components/ui/button';

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

import { useState } from 'react';
import { Edit } from 'lucide-react';
import { UpdatePhoneForm } from './update-phone-form';

export function UpdatePhoneDialog({ phone }: { phone?: string }) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {/* <Button variant="outline">Edit Profile</Button> */}
        <Button variant="outline">
          <Edit className="size-4 mr-2" />
          Click here to update
        </Button>
      </DialogTrigger>
      <DialogContent className="">
        <UpdatePhoneForm
          phone={phone}
          layoutClasses="border-none"
          setOpen={setOpen}
        />
      </DialogContent>
    </Dialog>
  );
}
