import { Separator } from '@/components/ui/separator';
import { ApplicationFormContainer } from './application-form-container';

export default function ApplicationFormView() {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium">Application Form</h3>
        <p className="text-sm text-muted-foreground">
          Customize the application form process for this position
        </p>
      </div>
      <Separator />
      <ApplicationFormContainer />
    </>
  );
}
