import { Button } from '@/components/ui/button';
import { useEffect, useLayoutEffect, useState } from 'react';
import { HelpCircle, Phone, PhoneCall } from 'lucide-react';
import PhoneInput from 'react-phone-input-2';
import {
  cn,
  getContrastColorFromBackgroundColor,
  getDominantColor,
} from '@/lib/utils';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';
import { useParams, useSearch } from '@tanstack/react-router';
import {
  useConfirmationPageStartInterview,
  useGetInterviewConfirmationPageDetails,
  useUpdateInterviewConfirmationMutation,
} from '@/fetchers/useInterview';
import logo from '@/assets/svg/logo-whitebg.svg';
import useDims from '@/lib/useDims';
import { useIsMutating } from '@tanstack/react-query';

import { Loader } from '../loader';
import { MIME_TYPE, UploadInput } from '../common/UploadInput';
import { InterviewGuideline } from './interview-guideline';

// TEST_URL = http://localhost:3000/interviews//37944a8d-4ec8-42d9-83ec-aac9020db2b7/confirmation?phone=%2B13023640393

type THeaderProps = {
  logo: string;
  title: string;
  organizationUrl?: string;
  supportLink: string;
};
function Header({
  logo,
  title,
  organizationUrl,
  supportLink,
}: Readonly<THeaderProps>) {
  const { isMobile } = useDims();
  const { showNewMessage, openChat } = useIntercomMessenger({
    customAttributes: {
      isCandidate: true,
    },
  });
  return (
    <div className="w-full sticky top-0 z-10 mt-6 mb-4 lg:mt-4 bg-white">
      <div className="container w-full  py-2  flex justify-between  flex-col md:flex-row  ">
        <div className="flex  items-center justify-center ">
          <div className="rounded overflow-hidden size-[60px] ">
            <img
              src={logo}
              // className="object-fit"
              alt="Company Logo"
              style={{
                width: '60px',
                height: '60px',
                // objectFit: "fill",
                aspectRatio: 1,
              }}
            />
          </div>

          <div className="flex  flex-col ml-4">
            <p className="font-bold text-2xl">{title}</p>
            {/* <p className="text-sm text-gray-700">{subtitle}</p> */}
            {/* mailto: */}
            <Button variant={'link'} className="w-14 h-6">
              <a
                href={`mailto:${supportLink}`}
                className="text-sm text-gray-700"
              >
                Contact
              </a>
            </Button>
          </div>
        </div>

        <div className="text-center mt-2 flex items-center gap-2 justify-center">
          <Button
            variant={'link'}
            onClick={() => {
              openChat();
              showNewMessage('Hi! I need help with my interview');
            }}
            // onClick={handleSupportClick}
            // className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <HelpCircle className="mr-2 size-4" />
            Get Support
          </Button>

          {'organizationUrl' && (
            <a href={organizationUrl} target="__blank">
              <Button variant={isMobile ? 'link' : 'secondary'}>
                Company Website
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

function phoneParser(rawPhone: string | number) {
  let phone = decodeURIComponent(`${rawPhone}` || '');

  if (!phone.startsWith('+')) {
    phone = `+1${phone}`;
  }

  return phone;
}

export function ConfirmationPage() {
  // @ts-ignore
  const { phone: rawPhone } = useSearch({ strict: false });
  // @ts-ignore
  const { interviewUid } = useParams({ strict: false });
  const [confirmed, setConfirmed] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [hasResume, setHasResume] = useState<any>(true);
  const update = useUpdateInterviewConfirmationMutation();
  const isMutating = useIsMutating();

  const { data: details, isFetching } = useGetInterviewConfirmationPageDetails({
    interviewUUid: interviewUid,
  });

  useEffect(() => {
    if (!details) return;
    setHasResume(details.hasResume);
  }, [details]);

  useConfirmationPageStartInterview({ interviewUid, enabled: confirmed });

  const phone = phoneParser(rawPhone);

  useLayoutEffect(() => {
    if (!details) return;
    document.querySelector('.flag-dropdown')?.remove();
  }, [details]);

  if (isFetching) {
    return (
      <div className="w-screen h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div className="mx-auto  overflow-y-auto h-screen overflow-x-hidden w-screen h-screen flex justify-between flex-col">
      <Header
        logo={details?.organization?.logoUrl || ''}
        title={details?.organization?.name || ''}
        supportLink={details?.organization?.companyReachOutEmail || ''}
      />

      {/* <div className='bg-black h-[80px] w-full' /> */}

      <div
        className="flex flex-col items-center justify-center min-h-[max(300px,30vh)] space-y-6 p-8 text-center"
        style={{
          background: details?.organization?.color,
          color: getContrastColorFromBackgroundColor(
            details?.organization?.color,
            0.8
          ),
        }}
      >
        {/* <p className="font-bold text-3xl md:text-4xl">Join {organization?.name || "us"} !</p> */}
        <div className="font-bold text-2xl lg:text-4xl  max-w-2xl space-y-1">
          <p>Are you ready for your interview?</p>
          <p className="text-sm lg:text-lg opacity-50">
            {details?.positionName} at{' '}
            <span>{details?.organization?.name}</span>
          </p>
        </div>

        {/* <p className='text-xl'>Your phone number:</p> */}
        <div>
          <PhoneInput
            value={phone}
            // onlyCountries={["us"]}
            // onChange={field.onChange}
            country={'us'}
            disabled
            inputStyle={{
              color: getDominantColor(details?.organization?.color),
              opacity: 0.95,
            }}
            inputProps={{
              className: cn(
                `flex rounded-md border-none bg-background 
                pl-14 py-2 text-sm ring-offset-background file:border-0 
                file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground 
                focus-visible:outline-none focus-visible:ring-2
                focus-visible:ring-ring focus-visible:ring-offset-2 
                disabled:cursor-not-allowed`,
                `text-2xl md:text-3xl lg:text-4xl font-semibold px-6 p-4 text-center`
              ),
            }}
          />
        </div>

        {hasResume ? (
          confirmed ? (
            <div className="flex flex-col items-center">
              <Button variant={'outline'}>
                <PhoneCall className="text-center size-6 text-green-600 my-4 mr-4" />
                <p className="text-green-600 font-bold text-lg">Calling...</p>
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => setConfirmed(true)}
              className="text-lg md:text-2xl  md:px-6 md:py-8 mt-8"
            >
              <Phone className="size-4 md:size-6 mr-2" />
              Call me now
            </Button>
          )
        ) : (
          <UploadInput
            buttonOnly
            buttonOnlyText="Upload Your Resume to Begin"
            isLoading={isMutating > 0}
            layoutClasses="p-8 "
            // name='resume'
            // defaultValue={field.value}
            mimeType={MIME_TYPE.PDF}
            onChange={async (event) => {
              const file = event.target.files?.[0];

              if (!file) {
                console.log('no file');
                return;
              }

              await update({
                interviewUid,
                resume: file,
              });

              setHasResume(true);
            }}
          />
        )}
      </div>

      <InterviewGuideline phone={phone} confirmed={confirmed} />

      <a
        href="https://veton.ai"
        target="_blank"
        className="p-4 text-lg flex justify-center items-center h-[60px] sticky bottom-0 left-0 w-full bg-white border-t border-gray-100 "
      >
        Powered by{' '}
        <img
          alt="powered by veton.ai"
          src={logo}
          style={{ height: 25 }}
          className="ml-1 flex"
        />
      </a>
    </div>
  );
}
