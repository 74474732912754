import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
// import mailSent from '@/assets/svg/mail_sent.svg'
// @ts-ignore
import MailSentIcon from '@/assets/svg/mail_sent.svg?react';
// @ts-ignore
import FailedIcon from '@/assets/svg/failed.svg?react';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { MIME_TYPE, UploadInput } from '../../common/UploadInput';
import { educationLevelValues } from '../../position/details/constants';
import { TApplicationForm, TPosition } from '@/fetchers/usePosition';
import { useApplyMutation } from '@/fetchers/useApplication';
import { useState } from 'react';
import { RequiredStar } from '../../common/RequiredStar';
import {
  cn,
  colorShade,
  getContrastColorFromBackgroundColor,
  getDominantColor,
  getIsBright,
} from '@/lib/utils';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Checkbox } from '@/components/ui/checkbox';
import { toast } from 'sonner';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';

const profileFormSchema = z.object({
  name: z.string({ required_error: 'Please enter a name.' }),
  email: z
    .string({
      required_error: 'Please select an email to display.',
    })
    .email(),
  phone: z.string().min(10, { message: 'Please enter a valid phone number.' }),

  additionalInfo: z
    .string()
    .max(1000)
    .min(4, {
      message: 'Please enter a valid additional information.',
    })
    .optional(),
  address: z.string().optional(),
  linkedinProfile: z
    .string()
    .url({ message: 'Please enter a valid URL.' })
    .optional(),
  education: z.string().optional(),
  desiredSalary: z.number().optional(),

  resume: z.union([z.string(), z.instanceof(File)]),
  coverLetter: z.union([z.string(), z.instanceof(File)]).optional(),

  consentAgreed: z.boolean({
    required_error:
      'You must agree to the terms and conditions to apply this position',
  }),
});

export type ApplicationFormValues = z.infer<typeof profileFormSchema> & {
  consentAgreed: boolean;
};

// This can come from your database or API.
const defaultValues: Partial<ApplicationFormValues> = {
  name: undefined,
};

// @ts-ignore
const createProfileFormSchema = (config: TApplicationForm) => {
  const schema = {
    name: z.string({ required_error: 'Please enter a name.' }),
    email: z
      .string({
        required_error: 'Please select an email to display.',
      })
      .email(),
    phone: z
      .string()
      .min(10, { message: 'Please enter a valid phone number.' }),

    additionalInfo: z.string().max(1000).min(4, {
      message: 'Additional information should be at least 4 characters long.',
    }),
    address: z.string(),
    linkedinProfile: z.string().url({ message: 'Please enter a valid URL.' }),
    education: z.string(),
    desiredSalary: z.number(),

    resume: z.union([z.string(), z.instanceof(File)]),
    coverLetter: z.union([z.string(), z.instanceof(File)]),

    consentAgreed: z.boolean({
      required_error:
        'You must agree to the terms and conditions to apply this position',
    }),
  };

  Object.entries(config).forEach(([key, value]) => {
    if (value === 'optional') {
      // @ts-ignore
      schema[key] = schema[key].optional();
    } else if (value === 'disabled') {
      // @ts-ignore
      delete schema[key];
    }
  });

  return z.object(schema);
};

type TProps = {
  config: TApplicationForm;
  position: TPosition;
  themeColor?: string;
  formTitle?: string;
  onSubmit?: () => void;
};
export function ApplicationForm({
  config,
  position,
  themeColor,
  formTitle = 'Apply',
  onSubmit,
}: Readonly<TProps>) {
  const applyMutation = useApplyMutation();
  const isMutating = useIsMutating();
  const [submitted, setSubmitted] = useState(false);
  const queryClient = useQueryClient();
  const form = useForm<ApplicationFormValues>({
    resolver: zodResolver(createProfileFormSchema(config)),
    defaultValues,
  });

  const [errorMessage, setErrorMessage] = useState('');

  async function onSubmitHandler(data: ApplicationFormValues) {
    const { consentAgreed, ...rest } = data;
    if (!consentAgreed) {
      toast.error(
        'You must agree to the terms and conditions to apply this position'
      );
      return;
    }
    setSubmitted(true);

    const res = await applyMutation({
      data: {
        ...rest,
        phone: '+' + rest.phone,
      },
      slug: position.slug,
    });

    if (res?.error) {
      setErrorMessage(res?.error);
    }
    queryClient.invalidateQueries({
      queryKey: ['applications', 'admin', position?.slug],
    });

    onSubmit?.();
  }

  if (submitted && !isMutating) {
    return (
      <div className="flex items-center justify-center flex-col space-y-4 py-4 h-full overflow-auto px-1 text-center py-8 space-y-8">
        <h3 className="font-bold text-2xl ">
          {errorMessage
            ? 'Application Failed'
            : 'Thank you for your application'}
        </h3>
        {/* <img
          src={mailSent}
          alt="Application Sent"
          className="w-1/2 mx-auto"
        /> */}
        {errorMessage ? (
          <FailedIcon
            className="w-1/4 mx-auto h-[200px]	"
            style={{
              fill: colorShade(
                getDominantColor(themeColor),
                getIsBright(getDominantColor(themeColor)) ? -40 : 0
              ),
            }}
          />
        ) : (
          <MailSentIcon
            className="w-1/4 mx-auto h-[200px]	"
            style={{
              fill: colorShade(
                getDominantColor(themeColor),
                getIsBright(getDominantColor(themeColor)) ? -40 : 0
              ),
            }}
          />
        )}
        <p className="max-w-xs ">
          {errorMessage
            ? errorMessage
            : 'Your application was successfully submitted.'}
        </p>

        {errorMessage && (
          <Button
            onClick={() => {
              setSubmitted(false);
              setErrorMessage('');
              form.reset();
            }}
            style={{
              background: themeColor,
              color: getContrastColorFromBackgroundColor(themeColor),
            }}
          >
            Try Again
          </Button>
        )}
      </div>
    );
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandler)}
        className="space-y-4 py-4 h-full overflow-auto px-1"
      >
        <h3 className="font-bold text-xl">{formTitle}</h3>

        <div className="flex space-x-4 w-full">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  Full name
                  <RequiredStar hidden={config.name !== 'required'} />
                </FormLabel>
                <FormControl>
                  <Input placeholder="Full Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  Email
                  <RequiredStar hidden={config.email !== 'required'} />
                </FormLabel>
                <Input placeholder="Email Address" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Phone Number
                <RequiredStar hidden={config.phone !== 'required'} />
              </FormLabel>
              <FormControl>
                {/* <Input placeholder="Phone Number" {...field} /> */}

                <PhoneInput
                  value={field.value}
                  onChange={field.onChange}
                  country={'us'}
                  // onlyCountries={["us"]}
                  inputProps={{
                    className: cn(
                      `flex h-10 w-full rounded-md border border-input bg-background 
        pl-14 py-2 text-sm ring-offset-background file:border-0 
        file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground 
        focus-visible:outline-none focus-visible:ring-2
        focus-visible:ring-ring focus-visible:ring-offset-2 
        disabled:cursor-not-allowed disabled:opacity-50",
        className`
                    ),
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {config.education !== 'disabled' && (
          <FormField
            control={form.control}
            name="education"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Education
                  <RequiredStar hidden={config.education !== 'required'} />
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select the education" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {educationLevelValues.map(({ value, label }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {config.linkedinProfile !== 'disabled' && (
          <FormField
            control={form.control}
            name="linkedinProfile"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Linkedin Profile
                  <RequiredStar
                    hidden={config.linkedinProfile !== 'required'}
                  />
                </FormLabel>
                <Input placeholder="linkedin profile" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {config.address !== 'disabled' && (
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Address
                  <RequiredStar hidden={config.address !== 'required'} />
                </FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Address"
                    className="resize-none"
                    {...field}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {config.desiredSalary !== 'disabled' && (
          <FormField
            control={form.control}
            name="desiredSalary"
            // valueAsNumber
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Desired Salary ({position.salaryCurrency} -{' '}
                  {position.salaryType})
                  <RequiredStar hidden={config.desiredSalary !== 'required'} />
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    onChange={(event) => field.onChange(+event.target.value)}
                    placeholder="Desired Salary"
                    type="number"
                  />
                </FormControl>
                {/* <FormDescription>
                    In case you want to keep track of your job posts with an internal ID.
                  </FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {config.additionalInfo !== 'disabled' && (
          <FormField
            control={form.control}
            name="additionalInfo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Additional Information
                  <RequiredStar hidden={config.additionalInfo !== 'required'} />
                </FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Any additional information you would like to share"
                    className="resize-none"
                    {...field}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {config.coverLetter !== 'disabled' && (
          <FormField
            control={form.control}
            name="coverLetter"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Cover Letter
                  <RequiredStar hidden={config.coverLetter !== 'required'} />
                </FormLabel>

                <UploadInput
                  name="coverLetter"
                  defaultValue={field.value}
                  mimeType={MIME_TYPE.PDF}
                  onChange={(event) => {
                    const file = event.target.files?.[0];

                    if (!file) {
                      console.log('no file');
                      return;
                    }
                    field.onChange(file);
                  }}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="resume"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Resume
                <RequiredStar hidden={config.resume !== 'required'} />
              </FormLabel>

              <UploadInput
                name="resume"
                defaultValue={field.value}
                mimeType={MIME_TYPE.PDF}
                onChange={(event) => {
                  const file = event.target.files?.[0];

                  if (!file) {
                    console.log('no file');
                    return;
                  }
                  field.onChange(file);
                }}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="w-full flex justify-between items-center">
          <FormField
            control={form.control}
            name="consentAgreed"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    I agree to the{' '}
                    <a
                      href="https://www.veton.ai/terms-of-service"
                      className="underline underline-offset-4 hover:text-primary"
                      target="_blank"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://www.veton.ai/privacy-policy"
                      className="underline underline-offset-4 hover:text-primary"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    .
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
          <Button
            style={{
              background: themeColor,
              color: getContrastColorFromBackgroundColor(themeColor),
            }}
            className=""
            disabled={isMutating > 0}
            type="submit"
            isLoading={isMutating > 0}
          >
            Apply
          </Button>
        </div>
      </form>
    </Form>
  );
}
