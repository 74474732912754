export function AnalysisView({ analysis }: { analysis: string }) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex">
        <h1 className="text-3xl font-bold mt-2">
          Interview{' '}
          <span className="underline decoration-sky-500 ">Completed</span>
        </h1>
      </div>
      <p className="text-lg">Company has been notified.</p>

      <div className="container px-4 mt-4 mb-8">
        {/* <h2 className="underline decoration-sky-500 text-center pb-2 font-bold">Interview Analysis</h2> */}

        <div className="overflow-auto mt-4 h-[75vh] pb-8  border-black/50 rounded-lg border-2 shadow-xl p-4 bg-black/10">
          <div className="mt-2 lg:py-6 px-4 mx-auto max-w-screen-md">
            <p className="prose-slate whitespace-pre-line	">{analysis}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
