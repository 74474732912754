import AlertModal from '@/components/core/common/AlertModal';
import { Markdown } from '@/components/core/common/Markdown';
import { Loader } from '@/components/core/loader';
import {
  interviewVerdictValues,
  resumeVerdictValues,
} from '@/components/core/position/details/constants';
import { Badge } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import { SelectSeparator } from '@/components/ui/select';

import {
  ApplicationDetail,
  useGetApplication,
  useGetApplicationMessages,
  useGetMessageResponseRecording,
  useSendInterviewMutation,
} from '@/fetchers/useApplication';
import { cn, parseSalaryToDisplay } from '@/lib/utils';
import {
  createFileRoute,
  ErrorComponent,
  useNavigate,
} from '@tanstack/react-router';
import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  DownloadIcon,
  Ellipsis,
  NotepadText,
  PlayCircle,
  User2Icon,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  useDisqualifyCandidateMutation,
  useResetInterviewMutation,
} from '@/fetchers/useInterview';
import { Separator } from '@/components/ui/separator';
import pendingImage from '@/assets/svg/pending.svg';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ASSISTANT_TYPES } from '@/constants/interview';
import { Tabs } from '@/components/ui/tabs';
import { useCandidates } from '@/lib/useApplication/useCandidates';
import { z } from 'zod';
import { toast } from 'sonner';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { usePositionQuery } from '@/fetchers/usePosition';

const searchSchema = z.object({
  selectedTab: z.string().optional(),
  searchTerm: z.string().optional(),
});

export const Route = createFileRoute(
  '/organizations/$organizationId/positions/manage/$slug/$step/$id'
)({
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  validateSearch: searchSchema,
  pendingComponent: () => {
    return <Loader />;
  },
  component: CandidateInfo,
});

const rightPanelItems = [
  { title: 'Analysis', value: 'analysis' },
  { title: 'Custom Questions', value: 'questions' },
  { title: 'Recordings', value: 'recordings' },
  { title: 'Resume', value: 'resume' },
  { title: 'Cover Letter', value: 'cover-letter' },
];

export function CandidateInfo() {
  const { id, organizationId, slug } = Route.useParams();
  const [closeAlertModelOpen, setCloseAlertModelOpen] = useState<
    ApplicationDetail | boolean | undefined
  >(false);
  // const { auth: { user } } = useRouteContext({ strict: false })
  const [selectedTab, setSelectedTab] = useState('analysis');
  const disqualifyCandidate = useDisqualifyCandidateMutation();
  const { data: application, isFetching: isFetchingApplication } =
    useGetApplication({
      applicationId: Number(id),
    });
  const { data: messages } = useGetApplicationMessages({
    applicationId: Number(id),
    enabled: selectedTab === 'recordings',
  });
  const { data: positionData, isFetching: isFetchingPositionData } =
    usePositionQuery({
      slug,
    });
  const interview = application?.interviews?.[0];
  // const { candidates } = useCandidateStore(store => store)
  const [messageId, setMessageId] = useState<string | undefined>(undefined);
  const { data: recording } = useGetMessageResponseRecording({
    messageId: messageId || '',
    applicationId: Number(id),
  });
  const navigate = useNavigate();
  const { searchTerm } = Route.useSearch();
  const sendInterviewMutation = useSendInterviewMutation();
  const { candidates } = useCandidates({
    searchTerm: searchTerm || '',
    selectedTab: 'all',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [resetAlertModelOpen, setResetAlertModelOpen] = useState<any>(false);
  const resetInterview = useResetInterviewMutation();

  useEffect(() => {
    if (!interview) return;
    if (!interview?.assistantType) return;

    if (interview?.assistantType !== ASSISTANT_TYPES.RESTRICTED) return;

    setSelectedTab('questions');
    // setSelectedTab("recordings")
  }, [interview]);

  const currentIndex = candidates?.findIndex(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (candidate: any) => candidate?.applicationId === Number(id)
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nextCandidate: any =
    currentIndex !== undefined &&
    currentIndex >= 0 &&
    currentIndex < candidates.length - 1
      ? candidates[currentIndex + 1]
      : undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const previousCandidate: any =
    currentIndex !== undefined && currentIndex > 0
      ? candidates[currentIndex - 1]
      : undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleNextCandidate(candidate: any) {
    if (!candidate) return;
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
      params: {
        organizationId: String(organizationId),
        slug: slug,
        step: 'applications',
        id: candidate.applicationId,
      },
    });
  }

  function handleReportCardClick() {
    window.open(
      `/o/${organizationId}/positions/print/${slug}/applications/${id}`,
      '_blank'
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getApplicationStatus(application: any) {
    const interview = application?.interviews?.[0];

    if (interview?.finalVerdict) {
      return { status: 'completed', label: 'COMPLETED' };
    } else if (!interview?.finalVerdict && interview?.completedAt) {
      return { status: 'processesing', label: 'PROCESSING INTERVIEW' };
    } else if (!interview?.completedAt && interview?.startedAt) {
      return { status: 'in_progress', label: 'INTERVIEW IN PROGRESS' };
    } else if (interview && !interview?.startedAt) {
      return { status: 'interview_sent', label: 'INTERVIEW SENT' };
    } else if (!interview?.startedAt && application?.createdAt) {
      return { status: 'applied', label: 'APPLIED' };
    }
  }

  const firstInterviewUrl = application?.interviews?.find(
    (interview) => interview?.interviewUrl
  )?.interviewUrl;
  const dropdownActions = [
    firstInterviewUrl && {
      title: 'Copy Interview Url',
      onClick: () => {
        // window.open(firstInterviewUrl, "_blank")
        navigator.clipboard.writeText(firstInterviewUrl);
        toast.success('Interview URL copied to clipboard');
      },
    },

    (application?.interviews || [])?.length === 0 && {
      title: 'Send Interview',
      onClick: () =>
        sendInterviewMutation({
          applicationId: application?.id,
        }),
    },

    (application?.interviews || [])?.length > 0 && {
      title: 'Reset Interview',
      onClick: () => setResetAlertModelOpen(application),
      customClasses: 'font-bold cursor-pointer text-rose-500',
    },

    {
      title: 'Disqualify',
      onClick: () => setCloseAlertModelOpen(application),
      customClasses: 'font-bold cursor-pointer text-rose-500',
    },
  ].filter(Boolean);

  function handleDisqualifyCandidate() {
    if (!application?.id) return;
    disqualifyCandidate({
      applicationId: application?.id,
    });
  }

  async function handleResetInterview() {
    const interviewId = application?.interviews?.[0]?.id;
    if (!interviewId) return;
    await resetInterview({
      interviewId,
    });
  }

  if (isFetchingApplication || isFetchingPositionData) {
    return <Loader />;
  }

  return (
    <>
      <AlertModal
        open={!!closeAlertModelOpen}
        onClose={() => setCloseAlertModelOpen(false)}
        title="You are about to disqualify this candidate"
        body={`Are you sure you want to disqualify this candidate? This action cannot be undone. Once disqualified, the candidate will receive an email notification.`}
        confirmText="Disqualify Candidate"
        onProceed={handleDisqualifyCandidate}
      />

      <AlertModal
        open={!!resetAlertModelOpen}
        onClose={() => setResetAlertModelOpen(false)}
        title="You are about to reset the interview for this candidate"
        body={`Are you sure you want to reset the interview for this candidate? This action cannot be undone. Once reset, the candidate will receive an email notification.`}
        confirmText="Reset Interview"
        onProceed={handleResetInterview}
      />

      <Breadcrumb
        items={[
          {
            title: positionData?.title ?? '',
            href: `/organizations/${organizationId}/positions/manage/${slug}/details`,
          },
          {
            title: 'Applications',
            href: `/organizations/${organizationId}/positions/manage/${slug}/applications`,
          },
          {
            title: application?.name ?? '',
          },
        ]}
      />
      <Tabs
        defaultValue="all"
        // className='max-w-[1300px]'
        className="w-screen md:w-full"
      >
        <div className="flex justify-between flex-col md:flex-row">
          <div className="flex items-center px-4 py-2 no-print">
            <h1 className="text-xl font-bold">Candidate Information</h1>
          </div>

          <div className="p-2 flex items-center no-print gap-2">
            <Button
              variant={'outline'}
              className={cn(!previousCandidate && 'hidden')}
              onClick={() => handleNextCandidate(previousCandidate)}
            >
              <ChevronLeft className="size-4" />
              <p>{previousCandidate?.title}</p>
            </Button>
            <Button
              variant={'outline'}
              className={cn(!nextCandidate && 'hidden')}
              onClick={() => handleNextCandidate(nextCandidate)}
            >
              <p>{nextCandidate?.title}</p>
              <ChevronRight className="size-4" />
            </Button>
            <Button variant={'outline'} onClick={handleReportCardClick}>
              <DownloadIcon className="size-4 mr-1" />
              Report Card
            </Button>
          </div>
        </div>
        <SelectSeparator />

        <div className="grid grid-cols-4 gap-4 p-4 xl:p-8  max-h-[90vh] overflow-y-auto md:overflow-y-hidden">
          <div className="col-span-4 lg:col-span-2 max-h-[85vh] overflow-y-auto p-2">
            <div className="p-8 shadow-lg rounded-lg">
              <div className="mb-6 flex justify-between items-center">
                <div className="flex flex-col xl:items-center xl:flex-row">
                  <p className="font-bold text-3xl">{application?.name}</p>
                  <div className="xl:ml-4 mb-1">
                    <Badge className="text-center">
                      {getApplicationStatus(application)?.label}
                    </Badge>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  {application?.createdAt && (
                    <p className="text-gray-700 text-sm text-right">
                      Applied{' '}
                      {formatDistanceToNow(new Date(application?.createdAt), {
                        addSuffix: true,
                      })}
                    </p>
                  )}

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost">
                        <Ellipsis />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-42">
                      <DropdownMenuGroup>
                        {dropdownActions.map((action, idx) => (
                          <DropdownMenuItem
                            key={idx}
                            // @ts-ignore
                            onClick={action.onClick}
                            role="button"
                            className={cn(
                              'cursor-pointer',
                              // @ts-ignore
                              action.customClasses
                            )}
                          >
                            {/* @ts-ignore */}
                            {action?.title || ''}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>

              <Avatar className="size-[100px] text-4xl text-gray-400 ">
                {application?.userAvatar && (
                  <AvatarImage src={application.userAvatar} alt="User Avatar" />
                )}
                <AvatarFallback>
                  <User2Icon className="size-12" />{' '}
                </AvatarFallback>
              </Avatar>

              {/* <img
                src={application?.userAvatar}
                alt='user image'
                className='rounded-full'
                style={{
                  width: '100px',
                  height: '100px',
                }}
              /> */}

              <InformationTable application={application} />
            </div>

            {interview?.analysisSummary && (
              <div className="p-8 shadow-lg rounded-lg mt-12 mb-12 lg:mb-0">
                <div className="flex  items-center justify-between mb-6">
                  <h2 className="text-2xl font-bold">Interview Summary</h2>
                  <Badge>
                    {/* @ts-ignore */}
                    {interviewVerdictValues?.[
                      // @ts-ignore
                      interview?.finalVerdict
                    ]?.toUpperCase()}
                  </Badge>
                </div>
                <p>{interview?.analysisSummary}</p>
              </div>
            )}

            {application?.resumeAnalysis && (
              <div className="p-8 shadow-lg rounded-lg mt-12 mb-12 lg:mb-0">
                <div className="flex  items-center justify-between mb-6">
                  <h2 className="text-2xl font-bold">Resume Analysis</h2>
                  {/* @ts-ignore */}
                  <Badge>
                    {resumeVerdictValues?.[
                      // @ts-ignore
                      application?.resumeAnalysisVerdict
                    ]?.toUpperCase()}
                  </Badge>
                </div>
                <p>{application?.resumeAnalysis}</p>
              </div>
            )}
          </div>

          <div className="col-span-4 lg:col-span-2  p-2 ">
            <div className="shadow-lg rounded-lg h-[85vh] overflow-x-hidden ">
              <nav className="flex space-x-2 sticky top-0 bg-white p-4 overflow-auto z-10">
                {rightPanelItems.map((item) => {
                  if (
                    item.value === 'analysis' &&
                    interview?.assistantType === ASSISTANT_TYPES.RESTRICTED
                  )
                    return null;
                  if (
                    item.value === 'questions' &&
                    !interview?.additionalQuestionsAndAnswers
                  )
                    return null;
                  return (
                    <Button
                      key={item.value}
                      // @ts-ignore
                      // to={item.href}
                      onClick={() => setSelectedTab(item.value)}
                      variant="ghost"
                      className={cn(
                        buttonVariants({ variant: 'ghost' }),
                        // "pathname" === item.href
                        item.value === selectedTab
                          ? 'bg-muted hover:bg-muted'
                          : 'hover:bg-transparent hover:underline',
                        'justify-start'
                      )}
                    >
                      {item.title}
                    </Button>
                  );
                })}
              </nav>
              <Separator className="sticky top-[72px]" />

              {selectedTab === 'analysis' && (
                <div className="p-4 px-6">
                  <h2 className="text-2xl font-bold mb-4">
                    Interview Analysis
                  </h2>
                  {interview?.analysis && (
                    <Markdown content={interview?.analysis} />
                  )}
                  {!interview?.analysis && (
                    <EmptyTabView
                      title="No analysis available"
                      subtitle="Awaiting candidate to finish its interview to analyze"
                      image={pendingImage}
                    />
                  )}
                </div>
              )}

              {selectedTab === 'questions' && (
                <div className="p-4 px-6">
                  {interview?.additionalQuestionsAndAnswers && (
                    <div className="prose">
                      {interview?.additionalQuestionsAndAnswers.map(
                        (question, idx) => (
                          <div key={idx} className="mb-4">
                            <h3 className="text-lg font-bold">
                              {question.question}
                            </h3>
                            <p>{question.answer}</p>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {!interview?.additionalQuestionsAndAnswers && (
                    <EmptyTabView
                      title="No analysis available"
                      subtitle="Awaiting candidate to finish its interview to analyze"
                      image={pendingImage}
                    />
                  )}
                </div>
              )}

              {selectedTab === 'recordings' &&
                ((messages || [])?.length > 1 ? (
                  <div>
                    {interview?.audioRecordingUrl && (
                      <audio
                        controls
                        src={interview?.audioRecordingUrl}
                        className="w-full px-4 mt-4 mb-2"
                      >
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                    )}
                    {(messages || []).map((message, idx) => {
                      const content = message?.content
                        ?.replace(/Alice:/, '')
                        ?.replace(/Alex:/, '')
                        ?.replace(/Emma:/, '')
                        ?.replace(/James:/, '')
                        ?.replace(/HR Manager:/, '');
                      return (
                        <div className="p-4 px-6" key={message?.id || idx}>
                          <div className="flex justify-between items-center ">
                            <h2 className="text-lg font-bold mb-4">
                              {message?.role === 'user'
                                ? application?.name
                                : 'HR Manager'}
                              :
                            </h2>

                            {message?.role === 'user' &&
                              !interview?.audioRecordingUrl && (
                                <>
                                  {(!messageId ||
                                    messageId !== messages?.[idx - 1]?.id) && (
                                    <Button
                                      variant="outline"
                                      onClick={() =>
                                        setMessageId(messages?.[idx - 1]?.id)
                                      }
                                    >
                                      <PlayCircle />
                                    </Button>
                                  )}

                                  {messageId &&
                                  messageId === messages?.[idx - 1]?.id ? (
                                    <Button
                                      variant="outline"
                                      onClick={() => setMessageId(undefined)}
                                    >
                                      <NotepadText />
                                    </Button>
                                  ) : null}
                                </>
                              )}
                          </div>

                          <Markdown content={content} />

                          {message.role === 'user' &&
                            (messageId !== messages?.[idx - 1]?.id ? null : ( // <Markdown content={content} />
                              <video
                                autoPlay
                                controls
                                playsInline
                                src={recording?.url}
                                className="w-full h-[350px] px-4 mt-4 mb-2"
                              >
                                Your browser does not support the
                                <code>video</code> element.
                              </video>
                            ))}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <EmptyTabView
                    title="No recordings available"
                    subtitle="Awaiting candidate to start the interview to record messages"
                    image={pendingImage}
                  />
                ))}

              {selectedTab === 'resume' && (
                <div className="p-4 px-6">
                  {application?.resumeUrl ? (
                    <iframe
                      src={application?.resumeUrl?.split('?')?.[0] || ''}
                      width="100%"
                      height="85vh"
                      style={{
                        height: '85vh',
                      }}
                    ></iframe>
                  ) : (
                    <EmptyTabView
                      title="No resume available"
                      subtitle="Candidate didn't provide a resume."
                      image={pendingImage}
                    />
                  )}
                </div>
              )}

              {selectedTab === 'cover-letter' && (
                <div className="p-4 px-6">
                  {application?.coverLetterUrl ? (
                    <iframe
                      src={application?.coverLetterUrl}
                      width="100%"
                      height="85vh"
                      style={{
                        height: '85vh',
                      }}
                    ></iframe>
                  ) : (
                    <EmptyTabView
                      title="No cover letter available"
                      subtitle="Candidate didn't provide a cover letter."
                      image={pendingImage}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Tabs>
    </>
  );
}

function InformationTable({
  application,
}: {
  application?: ApplicationDetail;
}) {
  if (!application) return null;
  return (
    <div className="space-y-8 mt-8  break-all w-full ">
      {application?.email && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Email</span>
          </p>
          <p>{handleUndefinedValue(application?.email)}</p>
        </div>
      )}

      {application?.phone && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Phone</span>
          </p>
          <p>{handleUndefinedValue(application?.phone)}</p>
        </div>
      )}

      {application?.linkedinProfile && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Linkedin Profile</span>
          </p>
          <p>{handleUndefinedValue(application?.linkedinProfile)}</p>
        </div>
      )}

      {application?.additionalInfo && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Additional Information</span>
          </p>
          <p
            style={{
              wordBreak: 'break-word',
            }}
          >
            {handleUndefinedValue(application?.additionalInfo)}
          </p>
        </div>
      )}

      {application?.desiredSalary && application?.salaryCurrency && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Desired Salary</span>
          </p>
          {application?.desiredSalary === 'undefined' ? (
            <p>Blank</p>
          ) : (
            <p>
              {parseSalaryToDisplay(
                Number(application?.desiredSalary),
                application?.salaryCurrency
              )}{' '}
              / {application?.salaryType}
            </p>
          )}
        </div>
      )}

      {application?.education && (
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold mr-4">Education</span>
          </p>
          <p>{handleUndefinedValue(application?.education)}</p>
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleUndefinedValue(value: any) {
  if (value === 'undefined') {
    return 'Blank';
  } else {
    return value;
  }
}

function EmptyTabView({
  title,
  subtitle,
  image,
}: {
  title: string;
  subtitle: string;
  image: string;
}) {
  return (
    <div className="flex flex-col items-center mt-12 space-y-8">
      <p className="text-xl font-bold"> {title}</p>
      <img
        src={image}
        alt="pending image"
        className="rounded-lg"
        style={{
          width: '50%',
          height: 'auto',
        }}
      />
      <p className="mt-12 max-w-xs text-center">{subtitle}</p>
    </div>
  );
}
