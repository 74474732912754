import { createFileRoute } from '@tanstack/react-router';
import { Notifications } from '@/components/core/notifications';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { Layout } from '@/components/core/dashboard/layout';

export const Route = createFileRoute('/user/notifications')({
  component: UserNotificationPage,
});

function UserNotificationPage() {
  return (
    <Layout>
      <Breadcrumb
        items={[
          {
            title: 'Notifications',
          },
        ]}
      />
      <Notifications />
    </Layout>
  );
}

// None // Good Fits Only // All
// Applying Candidates

// Resume Analysis Results

// Interview Started

// Interview Completed

// Interview Analysis Result
