// import UploadImage from "~/assets/images/upload.svg";

import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { FileText, FileUpIcon, Loader2 } from 'lucide-react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export const MIME_TYPE = {
  PDF: 'application/pdf',
  IMG: 'image/*',
  DOC: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TEXT: 'text/plain',
  ALL: '*',
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

type TProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mimeType?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: File | string | any;
  name?: string;
  buttonOnly?: boolean;
  buttonOnlyText?: string;
  isLoading?: boolean;
  multiple?: boolean;
  layoutClasses?: string;
  // color?: boolean
};
export function UploadInput({
  onChange,
  mimeType = MIME_TYPE.ALL,
  defaultValue,
  name = 'file',
  buttonOnly = false,
  buttonOnlyText = 'Upload a file',
  multiple = false,
  layoutClasses = '',
  // buttonOnlyCustomClasses = "",
  isLoading = false,
  // color
}: Readonly<TProps>) {
  const hasImage = mimeType === MIME_TYPE.IMG && defaultValue;
  const imageUrl =
    defaultValue instanceof File
      ? URL.createObjectURL(defaultValue)
      : defaultValue;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    onChange({
      target: {
        files: acceptedFiles,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  let Icon = FileText;
  if (mimeType === MIME_TYPE.IMG) {
    Icon = PhotoIcon;
  }

  if (buttonOnly) {
    return (
      <label
        htmlFor={`${name}-upload`}
        // className="relative cursor-pointer rounded-md bg-black font-semibold  focus-within:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2 p-4 hover:text-gray-500 text-gray-100 text-lg"
        className={cn(
          buttonVariants({
            size: 'lg',
          }),
          'text-md cursor-pointer',
          layoutClasses
        )}
      >
        <div className="flex items-center space-x-2">
          {isLoading ? (
            <Loader2 className="size-5 animate-spin" />
          ) : (
            <FileUpIcon className="size-5" />
          )}
          <span>
            {defaultValue?.name ? 'Upload another file' : buttonOnlyText}
          </span>
        </div>
        <input
          disabled={isLoading}
          id={`${name}-upload`}
          name={`${name}-upload`}
          accept={mimeType}
          onChange={onChange}
          type="file"
          className="sr-only"
          multiple={multiple}
        />
      </label>
    );
  }

  return (
    <div className="w-full relative" {...getRootProps()}>
      <input {...getInputProps()} />

      {hasImage && (
        <div className="absolute inset-0 w-full h-full z-0 overflow-hidden">
          <img className="object-cover w-full h-full z-0" src={imageUrl} />
        </div>
      )}

      <div
        className={cn(
          'mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 z-20 relative ',
          hasImage && 'opacity-0 hover:opacity-100 bg-black/50'
        )}
      >
        <div className="text-center">
          <Icon
            className={cn(
              'mx-auto size-12',
              defaultValue ? 'text-green-700/75' : 'text-gray-300'
            )}
          />
          <p className={'text-green-700/75'}>{defaultValue?.name}</p>
          <div
            className={cn(
              'mt-4 flex text-sm leading-6 items-center',
              hasImage ? 'text-gray-200' : 'text-gray-600'
            )}
          >
            <label
              htmlFor={`${name}-upload`}
              className="relative cursor-pointer rounded-md bg-white font-semibold text-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2 hover:text-gray-500 p-1"
            >
              <span>
                {defaultValue?.name
                  ? 'Upload another file'
                  : multiple
                    ? 'Upload files'
                    : 'Upload a file'}
              </span>
              {/* <input id="file-upload" name="file-upload" type="file" className="sr-only" /> */}

              {/* mimetype === IMG && defaultValue render image in the background */}

              <input
                id={`${name}-upload`}
                name={`${name}-upload`}
                accept={mimeType}
                onChange={onChange}
                type="file"
                disabled={isLoading}
                className="sr-only"
                multiple={multiple}
              />
            </label>
            {!defaultValue?.name && <p className="pl-1">or drag and drop</p>}
          </div>
          {!defaultValue?.name && (
            <p
              className={cn(
                'text-xs leading-5',
                hasImage ? 'text-gray-200' : 'text-gray-600'
              )}
            >
              up to 10MB
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
