import { cn } from '@/lib/utils';
import { Button, buttonVariants } from '@/components/ui/button';
import { Link, useRouteContext } from '@tanstack/react-router';
// import { SignIn } from "@clerk/clerk-react"
// import { UserAuthForm } from "@/app/examples/authentication/components/user-auth-form"
import logoBlack from '@/assets/svg/logo-blackbg.svg';
import logoWhite from '@/assets/svg/logo-whitebg.svg';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';

type TProps = {
  children: React.ReactNode;
  whiteBg?: boolean;
};
export default function AuthenticationPage({
  children,
  whiteBg = false,
}: Readonly<TProps>) {
  const {
    auth: { isSignedIn, user },
  } = useRouteContext({ strict: false });
  const { openChat } = useIntercomMessenger();

  return (
    <>
      {/* <div className="md:hidden">
        <p>mobile view</p>
      </div> */}
      <div
        className="bg-black w-full block md:hidden p-8 -mb-24"
        style={{
          display: window.innerHeight < 800 ? 'none' : undefined,
        }}
      >
        <img
          src={whiteBg ? logoWhite : logoBlack}
          alt="veton.ai inc logo"
          width={260}
          height={'100%'}
        />
      </div>
      <div className="container relative h-screen flex flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        {/* {!isSignedIn && (
          <Link
            to="/signin"
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "absolute right-4 top-4 md:right-8 md:top-8"
            )}
          >
            HR Platform
          </Link>
        )} */}

        {isSignedIn && (
          <div className="space-x-2 absolute right-4 top-4 md:right-8 md:top-8">
            <Button onClick={openChat} variant="link">
              Support
            </Button>
            <Link
              to="/logout"
              className={cn(buttonVariants({ variant: 'outline' }))}
            >
              Switch User
            </Link>
          </div>
        )}

        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div
            className={cn(
              'absolute inset-0 ',
              whiteBg ? 'bg-slate-100' : 'bg-zinc-900'
            )}
          />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <img
              src={whiteBg ? logoWhite : logoBlack}
              alt="veton.ai inc logo"
              width={260}
              height={'100%'}
            />
          </div>
          {/* <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Finding the perfect candidate isn't just about filling a vacancy; it's about discovering a future leader, innovator, or game-changer for your team.&rdquo;
              </p>
              <footer className="text-sm">Chat Gpt</footer>
            </blockquote>
          </div> */}
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 w-full items-center">
            {/* <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Create an account
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email below to create your account
              </p>
            </div> */}
            {/* <UserAuthForm /> */}
            {children}

            {isSignedIn ? (
              <div className="absolute bottom-4 right-12">
                <p>Logged in as {user?.emailAddresses?.[0]?.emailAddress}</p>
              </div>
            ) : (
              <p className="px-8 text-center text-sm text-muted-foreground w-[350px]">
                By clicking continue, you agree to our{' '}
                <a
                  href="https://www.veton.ai/terms-of-service"
                  className="underline underline-offset-4 hover:text-primary"
                  target="__blank"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href="https://www.veton.ai/privacy-policy"
                  className="underline underline-offset-4 hover:text-primary"
                  target="__blank"
                >
                  Privacy Policy
                </a>
                .
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
