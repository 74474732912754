import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { TriangleAlert, XIcon } from 'lucide-react';
import { useGetBilling } from '@/fetchers/useOrganization';
import { Link } from '@tanstack/react-router';

import { create } from 'zustand';
import { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

const useBillingAlertStore = create<{
  show: boolean;
  setShow: (show: boolean) => void;
}>()((set) => ({
  show: true,
  setShow: (show?: boolean) => set({ show }),
}));

export function BillingAlert({
  layoutClasses = '',
}: {
  layoutClasses: string;
}) {
  const { data: billing } = useGetBilling({ enabled: true });
  const [show, setShow] = useBillingAlertStore((state) => [
    state.show,
    state.setShow,
  ]);

  const standing = billing?.standing || 'normal';
  const isBillingPage = window.location.pathname === '/billing';

  useEffect(() => {}, [standing]);

  const shouldDisplay = isBillingPage || show;
  return (
    <>
      {standing === 'headsup' && shouldDisplay && (
        <div className={layoutClasses}>
          <Alert className="text-left bg-yellow-300 text-yellow-900">
            <TriangleAlert className="h-4 w-4 stroke-yellow-900" />
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              <p>You are approaching the Interview limit of your plan.</p>
              {!isBillingPage && (
                <p>
                  Visit the{' '}
                  <Link to="/billing" className="underline">
                    Billing Page
                  </Link>{' '}
                  to upgrade your plan.
                </p>
              )}

              <Button
                onClick={() => setShow(false)}
                variant="ghost"
                className={cn(
                  'absolute top-2 right-2',
                  isBillingPage && 'hidden'
                )}
              >
                <XIcon />
              </Button>
            </AlertDescription>
          </Alert>
        </div>
      )}

      {standing === 'warning' && shouldDisplay && (
        <div className={layoutClasses}>
          <Alert className="text-left bg-orange-500 text-orange-100">
            <TriangleAlert className="h-4 w-4 stroke-orange-100" />
            <AlertTitle>Warning</AlertTitle>
            <AlertDescription>
              <p>
                You've exceeded the Interview limit of your plan. Don't worry,
                we have added an additional 10% capacity not to interrupt your
                interviews.
              </p>
              {!isBillingPage && (
                <p>
                  Visit the{' '}
                  <Link to="/billing" className="underline">
                    Billing Page
                  </Link>{' '}
                  to upgrade your plan.
                </p>
              )}
            </AlertDescription>

            <Button
              onClick={() => setShow(false)}
              variant="ghost"
              className={cn(
                'absolute top-2 right-2',
                isBillingPage && 'hidden'
              )}
            >
              <XIcon />
            </Button>
          </Alert>
        </div>
      )}

      {standing === 'critical' && (
        <div className={layoutClasses}>
          <Alert className="text-left bg-red-600 text-red-100">
            <TriangleAlert className="h-4 w-4 stroke-red-100" />
            <AlertTitle>Critical Warning</AlertTitle>
            <AlertDescription>
              <p>
                You've exceeded the Interview limit of your plan. You will no
                longer be able to send interview invites.
              </p>
              {!isBillingPage && (
                <p>
                  Visit the{' '}
                  <Link to="/billing" className="underline">
                    Billing Page
                  </Link>{' '}
                  to upgrade your plan.
                </p>
              )}
            </AlertDescription>
          </Alert>
        </div>
      )}
    </>
  );
}
