import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useInterviewTimeElapsedCounterStore = create<{
  interviews: {
    [key: string]: number;
  };
  setTimeElapsed: (id: number) => void;
}>()(
  persist(
    (set) => ({
      interviews: {},
      setTimeElapsed: (id: number) =>
        set((state) => ({
          interviews: {
            ...state.interviews,
            [id]: (state.interviews[id] || 0) + 1,
          },
        })),
    }),
    {
      name: 'organization-store',
    }
  )
);

type TProps = Readonly<{
  interviewId: number;
  start: boolean;
}>;
export function useInterviewTimeElapsedCounter({ interviewId, start }: TProps) {
  const { interviews, setTimeElapsed } = useInterviewTimeElapsedCounterStore();

  useEffect(() => {
    if (!start) return;
    const interval = setInterval(() => {
      setTimeElapsed(interviewId);
    }, 1000);

    return () => clearInterval(interval);
  }, [start]);

  return {
    timeElapsed: interviews[interviewId] || 0,
  };
}
