import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { toast } from 'sonner';
import { TextEditor } from '../../common/TextEditor';
import { TPosition, useUpdatePositionMutation } from '@/fetchers/usePosition';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useIsMutating } from '@tanstack/react-query';
import { VetonIndicator } from '../../common/VetonIndicator';
import { useEffect } from 'react';
import { usePositionManageRouteSearch } from '@/lib/usePositionManageRouteSearch';

const displayFormSchema = z.object({
  content: z
    .string({
      required_error: 'Content is required.',
    })
    .min(17, 'Content must be at least 10 characters.'),
});

type DisplayFormValues = z.infer<typeof displayFormSchema>;

// This can come from your database or API.
const defaultValues: Partial<DisplayFormValues> = {
  content: undefined,
};

export function DescriptionForm({ position }: { position?: TPosition }) {
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false });
  const { creating } = usePositionManageRouteSearch();
  const navigate = useNavigate();
  const isMutating = useIsMutating();
  const updatePosition = useUpdatePositionMutation();
  const form = useForm<DisplayFormValues>({
    resolver: zodResolver(displayFormSchema),
    defaultValues,
  });
  const { reset, control, handleSubmit } = form;

  useEffect(() => {
    if (position) {
      reset(position);
    }
  }, [position, reset]);

  async function onSubmit(payload: DisplayFormValues) {
    const { data, error } = await updatePosition({
      data: payload,
      organizationId: organizationId,
      slug,
    });

    if (data) {
      toast.success(`Position updated successfully`);
      if (creating) {
        navigate({
          to: '/organizations/$organizationId/positions/manage/$slug/$step',
          params: {
            organizationId: String(organizationId),
            slug: slug,
            step: 'application',
          },
          search: {
            creating: true,
          },
        });
      }
    } else {
      toast.error('Something went wrong. Please try again.', {
        description: error,
      });
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={control}
          name="content"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Job requirements & What to expect from the candidate
                <VetonIndicator required />
              </FormLabel>
              <FormDescription>
                This is the main description for the position. Write at least a
                few paragraphs to make sure candidates and Veton AI knows what
                is needed for this position
              </FormDescription>
              <FormControl>
                {/* <Textarea
                  placeholder="Tell us a little bit about yourself"
                  className="resize-none"
                  {...field}
                /> */}

                <TextEditor
                  // @ts-expect-error style works
                  // style={{ height: "80%" }}
                  className="h-[600px] pb-20"
                  theme="snow"
                  placeholder="Your detailed position description"
                  {...field}
                  // value={content}
                  // onChange={value => {
                  //   // setTextEditor(value)
                  //   setFormState({ ...formState, content: value })
                  // }}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          isLoading={isMutating > 0}
          className="float-right"
          type="submit"
        >
          {creating ? 'Next' : 'Update'}
        </Button>
      </form>
    </Form>
  );
}
