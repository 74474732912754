import { useEffect, useRef, useState } from 'react';
import {
  decodeAudioBuffer,
  inputBufferToMuLaw,
  payloadToWAV,
} from '@/lib/audioStream';
import { useGetWebGeneralApplication } from '@/fetchers/useApplication';
import { useVocalConversation } from '@/fetchers/useVocalConversation';
import { InterviewQuery } from '@/fetchers/useInterview';
import { useMediaStore } from './useMediaHanlder/useMediaStore';
import { uuid } from './utils';

const STREAM_URL =
  (import.meta.env.VITE_API_WS_URL as string) + '/ws/stream/twilio';

interface AudioData {
  event: string;
  url?: string;
  media?: {
    payload: string;
  };
  mark?: {
    name: string;
  };
}

export const useAudioStream = ({
  interviewId,
  assistantType,
  interview,
}: {
  interviewId?: number;
  assistantType?: string;
  interview?: InterviewQuery;
}) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);
  const audioBufferQueueRef = useRef<ArrayBuffer[]>([]);
  const isPlayingRef = useRef<boolean>(false);
  const currentSourceNodeRef = useRef<AudioBufferSourceNode | null>(null);
  const markReceivedRef = useRef<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [aiTalking, setAiTalking] = useState<boolean | undefined>();
  const greetFinished = useRef(false);
  const { setCurrentPresignedUrl } = useMediaStore();
  const { data } = useGetWebGeneralApplication({
    enabled: isStarted && !interview,
  });

  const interviewData = {
    interviewId: interview?.id || interviewId,
    assistantType: interview?.assistantType || data?.assistantType,
    openaiAssistantId: interview?.openaiAssistantId || data?.openaiAssistantId,
    openaiThreadId: interview?.openaiThreadId || data?.openaiThreadId,
    voiceModel: interview?.voiceModel || data?.voiceModel,
    callType: interview ? interview?.assistantType : 'general-application',
  };

  const { uploadMedia, recordMedia } = useVocalConversation({
    interviewId: interviewData.interviewId,
  });

  function endStream() {
    setIsStarted(false);

    // disconnect websocket
    if (websocketRef.current) {
      websocketRef.current.close();
    }

    // stop recording
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    handleClearSignal({ event: 'clear' });
  }
  useEffect(() => {
    if (!interviewData?.interviewId || !interviewData?.assistantType) return;
    if (!isStarted) return;

    if (aiTalking) {
      if (!greetFinished.current) return;
      console.log('upload previous media');
      uploadMedia();
    } else {
      console.log('start recording');
      recordMedia();
    }
  }, [isStarted, aiTalking, interviewId, assistantType]);

  useEffect(() => {
    if (
      data?.assistantType &&
      data?.openaiAssistantId &&
      data?.openaiThreadId &&
      data?.voiceModel
    ) {
      startRecording();
    }
  }, [data]);

  useEffect(() => {
    if (interview && isStarted) {
      startRecording();
    }
  }, [interview, isStarted]);

  useEffect(() => {
    websocketRef.current = new WebSocket(STREAM_URL);
    websocketRef.current.onopen = () => {
      console.log('WebSocket connected');
    };
    websocketRef.current.onclose = () => console.log('WebSocket disconnected');
    websocketRef.current.onmessage = (message: MessageEvent) => {
      const data: AudioData = JSON.parse(message.data);
      console.log('Incoming data event:', data);
      handleIncomingAudio(data);
      handleClearSignal(data);
      handleMarkEvent(data);
      handlePresignedUrl(data);
    };

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  const handleClearSignal = (data: AudioData) => {
    if (data.event !== 'clear') return;

    if (currentSourceNodeRef.current) {
      currentSourceNodeRef.current.stop();
      currentSourceNodeRef.current = null;
    }

    audioBufferQueueRef.current = [];
  };

  const handleMarkEvent = (data: AudioData) => {
    if (data.event !== 'mark') return;
    markReceivedRef.current = true;
  };

  function handlePresignedUrl(data: AudioData) {
    if (data.event !== 'signed-url' || !data.url) return;

    setCurrentPresignedUrl(data.url);
  }

  const sendStartEvent = () => {
    const startEvent = {
      event: 'start',
      start: {
        callSid: 'web',
        streamSid: uuid(),
        customParameters: {
          call_type: interviewData?.callType,
          openai_thread_id: interviewData?.openaiThreadId,
          openai_assistant_id: interviewData?.openaiAssistantId,
          assistant_type: interviewData?.assistantType,
          voice_model: interviewData?.voiceModel,
          interview_id: interviewData?.interviewId,
          initiator: 'web',
        },
      },
    };
    websocketRef.current?.send(JSON.stringify(startEvent));
  };

  const handleIncomingAudio = async (data: AudioData) => {
    if (data.event !== 'media' || !data.media) return;
    try {
      const wavData = payloadToWAV(data.media.payload);

      if (wavData) {
        audioBufferQueueRef.current.push(wavData);
        if (!isPlayingRef.current) {
          setAiTalking(true);
          await playAudioQueue();
        }
      }
    } catch (error) {
      console.error('Error handling incoming audio:', error);
    }
  };

  const playAudioQueue = async () => {
    isPlayingRef.current = true;
    while (audioBufferQueueRef.current.length > 0) {
      const wavData = audioBufferQueueRef.current.shift();
      if (wavData && audioContextRef.current) {
        try {
          const audioBuffer = await decodeAudioBuffer(
            audioContextRef.current,
            wavData
          );
          const source = audioContextRef.current.createBufferSource();
          source.buffer = audioBuffer;
          source.connect(audioContextRef.current.destination);
          currentSourceNodeRef.current = source;
          source.start(0);
          await new Promise<void>((resolve) => {
            source.onended = () => {
              resolve();
              currentSourceNodeRef.current = null;
            };
          });
        } catch (error) {
          console.error('Error decoding audio data:', error);
        }
      }
    }
    isPlayingRef.current = false;

    if (markReceivedRef.current && audioBufferQueueRef.current.length === 0) {
      sendEndSignal();
      markReceivedRef.current = false;
    }
  };

  const startRecording = async () => {
    sendStartEvent();
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        // @ts-ignore
        window.webkitAudioContext)({ sampleRate: 16000 });
    }

    if (audioContextRef.current.state === 'suspended') {
      await audioContextRef.current.resume();
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioInput =
          audioContextRef.current!.createMediaStreamSource(stream);
        const recorder = audioContextRef.current!.createScriptProcessor(
          2048,
          1,
          1
        );

        recorder.onaudioprocess = (e: AudioProcessingEvent) => {
          const base64Data = inputBufferToMuLaw(e.inputBuffer);
          // Only send if ai is not talking
          websocketRef.current?.send(
            JSON.stringify({
              event: 'media',
              media: {
                payload: base64Data,
              },
            })
          );
        };

        audioInput.connect(recorder);
        if (audioContextRef.current) {
          recorder.connect(audioContextRef.current.destination);
        }
      })
      .catch((err) => {
        console.error('Error capturing audio: ', err);
      });
  };

  const sendEndSignal = () => {
    websocketRef.current?.send(
      JSON.stringify({
        event: 'mark',
        mark: { name: 'END_SIGNAL' },
      })
    );
    if (!greetFinished.current) {
      greetFinished.current = true;
    }
    setAiTalking(false);
  };

  return {
    data,
    isStarted,
    setIsStarted,
    aiTalking,
    endStream,
  };
};
