import { cn } from '@/lib/utils';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  XIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { ModalDeprecated } from './ModalDeprecated';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';

type TProps = Readonly<{
  shareUrl: string;
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}>;

export default function SocialSharePanel({
  shareUrl,
  title,
  open,
  setOpen,
}: TProps) {
  const shareMessage = 'Check out this job!';
  return (
    <ModalDeprecated
      open={open}
      setOpen={setOpen}
      layoutClasses={cn('mt-24 w-full  max-w-md z-50')}
    >
      <div>
        <div className={cn('px-2 py-3 sm:p-4')}>
          <h3 className={cn('text-lg leading-6 font-medium text-gray-900')}>
            {title}
          </h3>
          <div className={cn('mt-2 max-w-xl text-sm text-gray-500')}>
            <div className={cn('space-x-2 -mb-2 space-y-2')}>
              <EmailShareButton url={shareUrl} title={shareMessage}>
                <EmailIcon size={32} round />
              </EmailShareButton>
              <FacebookShareButton url={shareUrl} title={shareMessage}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={shareMessage}>
                <XIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl} title={shareMessage}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <RedditShareButton url={shareUrl} title={shareMessage}>
                <RedditIcon size={32} round />
              </RedditShareButton>
              <WhatsappShareButton url={shareUrl} title={shareMessage}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <TelegramShareButton url={shareUrl} title={shareMessage}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
          </div>
          <div className={cn('mt-5 sm:flex sm:items-center')}>
            <div className={cn('w-full sm:max-w-xs')}>
              <input
                value={shareUrl}
                type={'text'}
                disabled
                className={cn(
                  'bg-gray-100 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md'
                )}
              />
            </div>

            <Button
              className="ml-3"
              onClick={() => {
                toast.success('Copied to clipboard');
              }}
            >
              <CopyToClipboard text={shareUrl}>
                <p>Copy</p>
              </CopyToClipboard>
            </Button>

            {/* <Tooltip
              layoutClasses={cn('sm:mt-0 sm:ml-3 mt-3 sm:w-auto')}
              infoText={"Copied"}>
              <CopyToClipboard text={shareUrl}>
                <p>Copy</p>
              </CopyToClipboard>
            </Tooltip> */}
          </div>
        </div>
      </div>
    </ModalDeprecated>
  );
}
