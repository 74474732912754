import { Separator } from '@/components/ui/separator';
import { DetailsForm } from './details-form';
import { TPosition } from '@/fetchers/usePosition';

export function DetailsFormView({ position }: { position?: TPosition }) {
  return (
    <>
      <h3 className="text-lg font-medium">Position Details</h3>
      <Separator />
      <DetailsForm position={position} />
    </>
  );
}
