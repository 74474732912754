import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent } from '@tanstack/react-router';

import { Route as PositionDefaultsRoute } from '@/routes/organizations.$organizationId.company.position-defaults';
import { Form } from '@/components/ui/form';
import { PositionApplicationForm } from '@/components/core/position/application/application-form';
import { Button } from '@/components/ui/button';
import { useIsMutating } from '@tanstack/react-query';
import {
  PositionApplicationFormValues,
  usePositionApplicationForm,
} from '@/lib/usePositionApplicationForm';
import {
  useGetOrganizationApplicationFormConfig,
  useUpdateOrganizationApplicationFormConfigMutation,
} from '@/fetchers/useOrganization';
import { useEffect } from 'react';
import { toast } from 'sonner';

export const Route = createRoute({
  getParentRoute: () => PositionDefaultsRoute,
  path: '/application',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyPositionDefaultsApplicationPage,
});

function CompanyPositionDefaultsApplicationPage() {
  const { organizationId } = Route.useParams();
  const isMutating = useIsMutating();
  const form = usePositionApplicationForm();
  const { handleSubmit, reset, watch } = form;
  const { data: organizationApplicationFormConfig } =
    useGetOrganizationApplicationFormConfig({
      organizationId: Number(organizationId),
    });
  const updateOrganizationApplicationFormConfig =
    useUpdateOrganizationApplicationFormConfigMutation();

  const isFormInitialized = watch('isInitialized');

  useEffect(() => {
    if (organizationApplicationFormConfig) {
      reset({
        ...organizationApplicationFormConfig,
        isInitialized: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationApplicationFormConfig]);

  const onSubmit = async (data: PositionApplicationFormValues) => {
    const res = await updateOrganizationApplicationFormConfig({
      //@ts-ignore
      data,
      organizationId: Number(organizationId),
    });
    if (res.data) {
      toast.success('Application form updated successfully!');
    } else {
      toast.error(res.error);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 w-full">
        <PositionApplicationForm />
        {isFormInitialized && (
          <Button
            isLoading={isMutating > 0}
            className="float-right"
            type="submit"
          >
            Save
          </Button>
        )}
      </form>
    </Form>
  );
}
