import { useRef, useEffect, useState } from 'react';
import Cropper from 'cropperjs';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';
import 'cropperjs/dist/cropper.css';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { PhotoIcon } from '@heroicons/react/24/solid';

type TProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onChange: (file: File) => void;
  aspectRatio: number;
  defaultValue?: string | null | File;
  layoutClasses?: string;
};

export const ImageCropper = ({
  open,
  setOpen,
  onChange,
  aspectRatio,
  defaultValue,
  layoutClasses = '',
}: TProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [imageUrl, setImageUrl] = useState(defaultValue);

  useEffect(() => {
    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
  }, [cropper]);

  const initializeCropper = () => {
    if (imageRef.current) {
      if (cropper) {
        cropper.destroy();
      }
      setCropper(
        new Cropper(imageRef.current, {
          aspectRatio: aspectRatio,
          viewMode: 1,
          autoCropArea: 0.8,
        })
      );
    }
  };

  useEffect(() => {
    if (open && imageUrl) {
      initializeCropper();
    }
  }, [open, imageUrl]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && imageRef.current) {
          imageRef.current.src = e.target.result as string;
          if (cropper) {
            cropper.destroy();
          }
          setCropper(
            new Cropper(imageRef.current, {
              aspectRatio: aspectRatio,
              viewMode: 1,
              autoCropArea: 0.8,
            })
          );
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (cropper && fileInputRef.current && fileInputRef.current.files) {
      const canvas = cropper.getCroppedCanvas();
      canvas.toBlob((blob) => {
        const fileName =
          fileInputRef?.current?.files?.[0]?.name || 'cropped-image.png';
        const fileExt = fileName.slice(fileName.lastIndexOf('.'));
        const newFileName = `cropped${fileExt}`;
        if (blob) {
          const file = new File([blob], newFileName, { type: 'image/png' });
          onChange(file);
          setImageUrl(URL.createObjectURL(file));
          setOpen(false);
        }
      }, 'image/png');
    }
  };

  return (
    <>
      <div className={cn('w-full relative rounded', layoutClasses)}>
        {imageUrl && typeof imageUrl === 'string' && (
          <div className="absolute inset-0 w-full h-full z-0 overflow-hidden">
            <img className="object-cover w-full h-full z-0" src={imageUrl} />
          </div>
        )}

        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 items-center z-20 relative w-full h-full">
          <div className="text-center">
            <div
              className={cn(
                'flex flex-col items-center justify-center',
                imageUrl && 'opacity-0 hover:opacity-100'
              )}
            >
              <PhotoIcon
                className={cn(
                  'mx-auto size-16',
                  imageUrl ? 'hidden' : 'text-gray-300'
                )}
              />
              {/* <p className="text-gray-800">Upload image up to 10mb</p> */}
              <Button
                variant={imageUrl ? 'default' : 'outline'}
                onClick={(event) => {
                  event.preventDefault();
                  setOpen(true);
                  fileInputRef?.current?.click?.();
                }}
              >
                {imageUrl ? 'Change' : 'Upload'}
              </Button>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </div>
        </div>

        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Image</DialogTitle>
              <DialogDescription>Crop your image as desired</DialogDescription>
            </DialogHeader>
            <div>
              <img
                ref={imageRef}
                alt="Please pick an image to crop"
                style={{ maxWidth: '100%' }}
              />
            </div>
            <DialogFooter>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                Close
              </Button>
              <Button onClick={handleSave}>Save changes</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ImageCropper;
