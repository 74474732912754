/* eslint-disable @typescript-eslint/no-explicit-any */
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export function useExport() {
  const exportToExcel = (data: any, fileName: any) => {
    // Define column order
    const columnOrder = [
      'Application ID',
      'Interview ID',
      'Name',
      'Email',
      'Phone',
      'Resume Analysis Verdict',
      'Interview Verdict',
      'Interview Started At',
      'Interview Completed At',
      'Interview Analysis',
      'Resume Analysis',
      'Candidate Details URL',
      'Archived',
    ];

    // Set column widths and styles
    const columnWidths = {
      'Application ID': 15,
      'Interview ID': 15,
      Name: 20,
      Email: 25,
      Phone: 15,
      'Resume Analysis Verdict': 15,
      'Interview Verdict': 15,
      'Interview Started At': 20,
      'Interview Completed At': 20,
      'Interview Analysis': 35,
      'Resume Analysis': 35,
      'Candidate Details URL': 30,
      Archived: 10,
    };

    // Reorder data according to columnOrder
    const sortedData = data.map((row: any) => {
      const newRow = {};
      columnOrder.forEach((key) => {
        // @ts-ignore
        newRow[key] = row[key];
      });
      return newRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(sortedData);

    // Add headers (now they'll be in the correct order)
    XLSX.utils.sheet_add_aoa(worksheet, [columnOrder], { origin: 'A1' });

    worksheet['!cols'] = columnOrder.map((header) => ({
      // @ts-ignore
      wch: columnWidths[header] || 20,
      hidden: false,
    }));

    // @ts-ignore
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    worksheet['!rows'] = [];
    for (let R = range.s.r; R <= range.e.r; ++R) {
      worksheet['!rows'][R] = { hpt: 30 }; // Set minimum row height (adjust as needed)
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellRef]) continue;
        worksheet[cellRef].s = {
          alignment: { vertical: 'top', wrapText: true },
          font: R === 0 ? { bold: true } : {},
        };
      }
    }

    // Create workbook and append worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate and save file
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
      cellStyles: true,
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return { exportToExcel };
}
