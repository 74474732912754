import { Menu } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useSidebar } from '@/components/ui/sidebar';

export const CustomSidebarTrigger = () => {
  const { toggleSidebar } = useSidebar();
  return (
    <Button variant="ghost" className="mr-auto mt-2" onClick={toggleSidebar}>
      <Menu />
    </Button>
  );
};

export default CustomSidebarTrigger;
