import { Loader } from '@/components/core/loader';

import { createRoute, ErrorComponent, Outlet } from '@tanstack/react-router';

import { useUserDetails } from '@/fetchers/useUserDetails';
import { Route as OrganizationsRoute } from '@/routes/organizations';

export const Route = createRoute({
  getParentRoute: () => OrganizationsRoute,
  path: '/$organizationId/company',
  errorComponent: ({ error }) => {
    console.log('error @applicants page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: CompanyDetailsPage,
});

function CompanyDetailsPage() {
  const { isFetching } = useUserDetails();

  if (isFetching) {
    return <Loader />;
  }

  return <Outlet />;
}
