import aliceAvatar from '@/assets/images/alice_avatar.png';
import alexAvatar from '@/assets/images/alex_avatar.png';
import emmaAvatar from '@/assets/images/emma_avatar.png';
import jamesAvatar from '@/assets/images/james_avatar.png';
import { AI_VOICES } from '../details/constants';

export type TInterviewer = {
  voiceModel: string;
  name: string;
  image: string;
  voice: string;
};
export const interviewers: TInterviewer[] = [
  {
    voiceModel: 'echo',
    name: 'Alex (US Male)',
    image: alexAvatar,
    voice: AI_VOICES.avatarAlex,
  },
  {
    voiceModel: 'shimmer',
    name: 'Alice (US Female)',
    image: aliceAvatar,
    voice: AI_VOICES.avatarAlice,
  },
  {
    voiceModel: 'nova',
    name: 'Emma (US Female)',
    image: emmaAvatar,
    voice: AI_VOICES.avatarEmma,
  },
  {
    voiceModel: 'onyx',
    name: 'James (US Male)',
    image: jamesAvatar,
    voice: AI_VOICES.avatarJames,
  },
];
