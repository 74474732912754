import { MoreHorizontal } from 'lucide-react';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { TAdminDashboard } from '@/fetchers/useOrganization';
import { useNavigate } from '@tanstack/react-router';

type TProps = {
  items: TAdminDashboard[];
  title: string;
  description: string;
};

export function AdminDashboardComponent({ items, title, description }: TProps) {
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="h-[70vh] overflow-auto ">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Applicant</TableHead>
              <TableHead className="hidden md:table-cell">
                Organization
              </TableHead>
              <TableHead className="hidden md:table-cell">Position</TableHead>
              <TableHead>Final Verdict</TableHead>
              <TableHead className="hidden md:table-cell">Completed</TableHead>
              <TableHead className="hidden md:table-cell">Reminders</TableHead>
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {items.map((item) => (
              <TableRow className="cursor-pointer" key={item.id}>
                <TableCell className="">
                  <div>
                    <p className="font-bold text-lg">{item.applicantName}</p>
                    <p>{item.applicantEmail}</p>
                    {/* <p className="text-sm opacity-50">{item.applicationId}</p> */}
                  </div>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  {item.organizationName}
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  {item.positionTitle}
                </TableCell>
                <TableCell>
                  <Badge variant="outline">{item.finalVerdict}</Badge>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  {new Date(item.completedAt).toLocaleDateString()}
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  {item.reminderCount}
                </TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button aria-haspopup="true" size="icon" variant="ghost">
                        <MoreHorizontal className="h-4 w-4" />
                        <span className="sr-only">Toggle menu</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuLabel>Actions</DropdownMenuLabel>
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => {
                          navigate({
                            to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
                            params: {
                              organizationId: String(item.organizationId),
                              slug: item.slug,
                              step: 'applications',
                              id: String(item.applicationId),
                            },
                          });
                        }}
                      >
                        Candidate Report
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => {
                          navigate({
                            to: `/admin-panel/organizations/$id`,
                            params: {
                              id: String(item.organizationId),
                            },
                          });
                        }}
                      >
                        Organization Dashboard
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-50</strong>
        </div>
      </CardFooter>
    </Card>
  );
}
