import { Button } from '@/components/ui/button';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';
import { ManatalConnection } from "./ManatalConnection"
import { useState } from 'react';
import { ChevronLeft } from 'lucide-react';

import { WorkableConnection } from './WorkableConnection';
import { LeverConnection } from './LeverConnection';
import { BreezyConnection } from './BreezyConnection';
import { ZohoRecruitConnection } from './ZohoRecruitConnection';
import { CONNECTIONS } from '../position/details/constants';
import { useParams } from '@tanstack/react-router';
import { useATSConnect } from '@/fetchers/useOrganization';
import { JazzHRConnection } from './JazzHRConnection';
import { RecruiterFlow } from './RecruiterFlow';
import { XMLFeedConnection } from './XMLFeedConnection';
import { CrelateConnection } from './Crelate';

export function ATSItems() {
  const { showNewMessage } = useIntercomMessenger();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [integration, setIntegration] = useState<any>();
  // @ts-ignore
  const { organizationId } = useParams({ strict: false });
  const atsConnect = useATSConnect();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSubmitHandler(data: any) {
    console.log('onSubmitHandler', data);
    atsConnect({
      authMethod: integration.authMethod,
      integrationType: integration.id,
      organizationId: Number(organizationId),
      credentials: data,
    });
  }

  if (integration) {
    return (
      <div className="space-y-8 py-6 min-h-[50vh] px-4 overflow-x-auto ">
        <Button
          variant={'ghost'}
          className="flex items-center -mt-6"
          onClick={() => setIntegration(undefined)}
        >
          <ChevronLeft className="size-3 mr-1" />
          <p className="font-medium">Back</p>
        </Button>
        {integration.id === 'WORKABLE' && (
          <WorkableConnection info={integration} onSubmit={onSubmitHandler} />
        )}
        {integration.id === 'Lever' && (
          <LeverConnection info={integration} onSubmit={onSubmitHandler} />
        )}
        {integration.id === 'ZOHO_RECRUIT' && (
          <ZohoRecruitConnection info={integration} />
        )}
        {integration.id === 'BreezyATS' && (
          <BreezyConnection info={integration} onSubmit={onSubmitHandler} />
        )}
        {integration.id === 'JAZZ_HR' && (
          <JazzHRConnection info={integration} onSubmit={onSubmitHandler} />
        )}
        {integration.id === 'RECRUITER_FLOW' && (
          <RecruiterFlow info={integration} onSubmit={onSubmitHandler} />
        )}
        {integration.id === 'CRELATE' && (
          <CrelateConnection info={integration} onSubmit={onSubmitHandler} />
        )}
        {integration.id === 'XML_FEED' && (
          <XMLFeedConnection info={integration} onSubmit={onSubmitHandler} />
        )}
        {integration.id === "MANATAL" && (
          <ManatalConnection info={integration} onSubmit={onSubmitHandler} />
        )}
      </div>
    );
  }
  return (
    <div className="space-y-8 py-6 h-[70vh] px-4 overflow-x-auto ">
      <h2 className="text-2xl font-bold">Select integration</h2>

      <div className="grid grid-cols-3 gap-2 ">
        {CONNECTIONS.map((connection) => (
          <Button
            variant={'outline'}
            key={connection.id}
            size={'lg'}
            className="flex items-center justify-center h-36"
            onClick={() => {
              if (connection.active) {
                // return connection.id === "workable" && <WorkableConnection />
                setIntegration(connection);
              } else {
                showNewMessage(
                  'Hi! I would like to connect to ' + connection.name
                );
              }
            }}
          >
            {/* <img src={connection.imageUrl} alt={connection.name} className="w-12 h-12" /> */}
            <div className="flex flex-col justify-center items-center">
              <img
                src={connection.imageUrl}
                alt={connection.name}
                className="h-8 w-26 object-cover"
              />
              <p>{connection.name}</p>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
}
