import { AdminDashboardComponent } from '@/components/core/admin/dashboard';
import { Loader } from '@/components/core/loader';
import { useGetAdminDashboard } from '@/fetchers/useOrganization';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/admin-panel/')({
  component: () => <DashboardPage />,
});

function DashboardPage() {
  // const { organizationId } = Route.useParams()
  const { data, isFetching } = useGetAdminDashboard({
    enabled: true,
  });

  if (isFetching) {
    return <Loader />;
  }

  console.log('items', data);
  return (
    <div>
      <AdminDashboardComponent
        items={data || []}
        title="Dashboard"
        description="Latest Interviews"
      />
    </div>
  );
}
