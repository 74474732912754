import { z } from 'zod';

import { useForm, UseFormProps } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  applicationEmailTemplate,
  applicationCompletedSMSTemplate,
  applicationSMSTemplate,
  disqualifiedEmailTemplate,
  interviewCompletedEmailTemplate,
  afterApplicationSubjectTemplate,
  afterInterviewSubjectTemplate,
  disqualifiedSubjectTemplate,
} from '@/components/core/position/communication/templates';
import { TCommunicationForm } from '@/fetchers/usePosition';

export const positionCommunicationFormSchema = z.object({
  selectedMethod: z.enum(['email', 'sms'], {
    required_error: 'Please select a method.',
  }),

  applicationEmail: z.string().optional(),
  interviewCompletedEmail: z.string().optional(),

  applicationSMS: z.string().optional(),
  applicationCompletedSMS: z.string().optional(),

  afterApplication: z.string().min(1, 'Required'),
  afterApplicationSubject: z
    .string()
    .min(1, 'Required')
    .default('New interview'),
  afterInterview: z.string().min(1, 'Required'),
  afterInterviewSubject: z
    .string()
    .min(1, 'Required')
    .default('Interview completed'),

  autoSendOption: z.enum(['send_all', 'good_fit', 'maybe_and_up']).optional(),

  disqualified: z.string().optional(),
  disqualifiedSubject: z
    .string()
    .optional()
    .default('Application status update'),

  disqualifiedToggle: z.enum(['enabled', 'disabled']).optional(),
  autoInterview: z.boolean().optional().default(false),

  interviewType: z.enum(['phone', 'video']),
  interviewDelay: z.enum([
    'no_delay',
    'fifteen_minutes',
    'thirty_minutes',
    'one_hour',
    'one_day',
    'two_days',
    'three_days',
  ]),
  isInitialized: z.boolean().default(false).optional(),
});

export type PositionCommunicationFormValues = z.infer<
  typeof positionCommunicationFormSchema
>;

// This can come from your database or API.
export const positionCommunicationFormDefaultValues: Partial<PositionCommunicationFormValues> =
  {
    selectedMethod: 'email',
    interviewType: 'phone',
    interviewDelay: 'no_delay',
    autoSendOption: 'send_all',

    applicationEmail: applicationEmailTemplate,
    interviewCompletedEmail: interviewCompletedEmailTemplate,

    applicationSMS: applicationSMSTemplate,
    applicationCompletedSMS: applicationCompletedSMSTemplate,

    afterApplicationSubject: afterApplicationSubjectTemplate,
    afterInterviewSubject: afterInterviewSubjectTemplate,
    disqualifiedSubject: disqualifiedSubjectTemplate,

    afterApplication: applicationEmailTemplate,
    afterInterview: interviewCompletedEmailTemplate,
    disqualified: disqualifiedEmailTemplate,

    disqualifiedToggle: 'disabled',
    autoInterview: true,
  };

export const handleMapFormValuesToData = (
  values: PositionCommunicationFormValues
): Partial<TCommunicationForm> => ({
  ...values,
  interviewDelay: values.autoInterview ? values.interviewDelay : 'no_delay',
  autoSendOption: values.autoInterview ? values.autoSendOption : 'send_all',
  disqualified:
    values.disqualifiedToggle === 'disabled' ? '' : values.disqualified,
  disqualifiedSubject:
    values.disqualifiedToggle === 'disabled' ? '' : values.disqualifiedSubject,
});

export const usePositionCommunicationForm = (
  options?: UseFormProps<PositionCommunicationFormValues>
) => {
  return useForm<PositionCommunicationFormValues>({
    resolver: zodResolver(positionCommunicationFormSchema),
    defaultValues: {
      ...positionCommunicationFormDefaultValues,
      ...options?.defaultValues,
    },
    ...options,
  });
};
