import { Separator } from '@/components/ui/separator';
import { Briefcase, FileText, Infinity, LifeBuoy, Users } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';
import {
  Tooltip,
  // TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { Badge } from '@/components/ui/badge';
// import { useOrganizationStore } from '@/fetchers/useOrganization';

type TTier = {
  amount: number;
  isMonthly: boolean;
  maxInterviewAllowed: number;
  maxApplicationAvailable: number;
  maxResumeAnalysisAvailable: number;
  atsIntegration: boolean;
  nextRenewalDate: string;
  planStatus?: string;
  currentPlan?: string;
};
type TProps = {
  // standing: "headsup" | "warning" | "critical" | "normal"
  tier: TTier;
  portalUrl?: string;
  metrics: {
    interviewsTotal: number;
    applicationsTotal: number;
    teamCount: number;
  };
};
export function BillingComponent({ tier, metrics }: Readonly<TProps>) {
  // const { selectedParentOrganization } = useOrganizationStore()
  const { showNewMessage } = useIntercomMessenger();

  function getPlanName(tier: TTier) {
    if (tier?.currentPlan) {
      if (tier?.currentPlan === 'free') {
        return 'Free Trial';
      }

      return (
        tier?.currentPlan?.charAt(0).toUpperCase() +
        tier?.currentPlan?.slice(1) +
        ' Plan'
      );
    }

    return 'Free Trial';
  }

  // const manageBillingDisabled = !portalUrl || selectedParentOrganization?.memberRole !== "admin"
  return (
    <div className="space-y-6 p-5 md:p-10 w-screen md:w-full lg:pb-16 h-screen overflow-auto lg:overflow-hidden max-w-5xl mx-auto">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Billing</h2>
        <p className="text-muted-foreground">
          Manage your billing and usage settings.
        </p>
      </div>
      <Separator className="my-6" />

      <div className="flex justify-between items-center">
        {/* <BillingAlert /> */}
      </div>

      <div className="border border-1 rounded shadow p-4">
        <div className="flex items-center w-full justify-between">
          <div className="flex flex-col lg:flex-row">
            {/* <h2 className='font-bold text-xl mr-4'>${tier.amount} per {tier.isMonthly ? "Month" : "Year"}</h2> */}
            <h3 className="font-bold text-xl mr-4">{getPlanName(tier)}</h3>
            {tier.nextRenewalDate && (
              <Badge variant={'secondary'} className="w-[fit-content]">
                {tier?.planStatus === 'cancelled'
                  ? 'EXPIRES AT'
                  : 'RENEWAL DATE'}
                : {tier.nextRenewalDate?.toUpperCase()}
              </Badge>
            )}
          </div>
          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    className="float-right"
                    variant={'outline'}
                    // disabled={manageBillingDisabled}
                    onClick={() => {
                      // if (!portalUrl) return
                      // window.open(portalUrl, '_blank')
                      showNewMessage('Hi! I would like to manage my billing.');
                    }}
                  >
                    Manage Billing
                  </Button>
                </TooltipTrigger>
                {/* {manageBillingDisabled && (
                  <TooltipContent>
                    <p>
                      Contact your Organization Admin to manage billing
                    </p>
                  </TooltipContent>
                )} */}
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div className="pt-4 gap-4 grid grid-cols-1 lg:grid-cols-3">
          {/* <TeamSettings searchTerm={searchTerm} /> */}

          <div className="space-y-4 col-span-1 ">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Total Interviews
                </CardTitle>
                {/* <Users className="h-4 w-4 text-muted-foreground" /> */}
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {metrics.interviewsTotal} / {tier.maxInterviewAllowed}
                </div>
                {/* <p className="text-xs text-muted-foreground">+180.1% from last month</p> */}
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Total Applicants & Resume Analysis
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold flex items-center ">
                  {metrics.applicationsTotal} /{' '}
                  <Infinity className="mt-1 font-bold ml-1" />
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Admin Seats
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold flex items-center ">
                  {metrics.teamCount} / 30
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  ATS Integration
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {tier.atsIntegration ? 'Included' : 'Not Included'}
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="col-span-2 relative ">
            <div className="space-y-4">
              <div className="w-full flex space-x-4">
                <FeatureBlock
                  title="Unlimited Job Positions"
                  subtitle="Create as many job positions as you want. There is no limit to the number of job positions you can create."
                  Icon={Briefcase}
                />
                <FeatureBlock
                  title="Unlimited Applications"
                  subtitle="Receive as many applications as you can get. There is no limit to the number of applications you can receive."
                  Icon={Users}
                />
              </div>
              <div className="w-full flex space-x-4">
                <FeatureBlock
                  title="Unlimited Resume Analysis"
                  subtitle="High volume of applicants? We got you covered. Analyze as many resumes as you want."
                  Icon={FileText}
                />
                <FeatureBlock
                  title="Support"
                  subtitle="Our team is always here to help you with any issues you may have. We are here to help"
                  Icon={LifeBuoy}
                />
              </div>
            </div>
            <Button
              className="absolute bottom-4 right-4 bg-blue-500 hover:bg-blue-600"
              onClick={() => {
                showNewMessage(
                  'Hi! I would like to talk to sales about my plan.'
                );
              }}
            >
              Talk to Sales
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function FeatureBlock({ Icon, title, subtitle }: any) {
  return (
    <div className="w-full  p-4">
      <Icon className="size-8 mb-4" />

      <p className="font-bold text-gray-800 text-lg">{title}</p>
      <p className="text-gray-600 text-sm">{subtitle}</p>
    </div>
  );
}
