import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@/components/ui/card';
import { TInterviewer } from './interviewers';
import AudioPlayer from '../../audio-player/audio-player';
import { useEffect, useState } from 'react';

type InterviewerCardProps = {
  interviewer: TInterviewer;
};

const InterviewerCard = ({ interviewer }: InterviewerCardProps) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const { voiceModel, name, image, voice } = interviewer;

  useEffect(() => {
    const audio = new Audio(voice);
    audio.addEventListener('loadedmetadata', (e) => {
      setAudio(e.currentTarget as HTMLAudioElement);
    });
  }, [voice]);

  return (
    <Card
      key={voiceModel}
      className="cursor-pointer hover:bg-accent transition-colors flex flex-col justify-center relative aspect-square"
    >
      <CardHeader className="p-4 ">
        <div className="w-24 h-24 mx-auto rounded-full overflow-hidden">
          <img src={image} alt={name} className="w-full h-full object-cover" />
        </div>
      </CardHeader>
      <CardContent className="text-center p-4 pt-0">
        <p className="text-sm font-medium">{name}</p>
      </CardContent>
      <CardFooter className="pb-4">
        {audio && <AudioPlayer audio={audio} />}
      </CardFooter>
    </Card>
  );
};

export default InterviewerCard;
