import { cn } from '@/lib/utils';
import { Ellipsis } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

export function ItemActions({
  dropdownActions,
}: {
  dropdownActions: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    customClasses?: string;
  }[];
}) {
  return (
    <div className="">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="mt-1 mr-1 hover:bg-transparent0"
          >
            <Ellipsis />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-36">
          {/* <DropdownMenuLabel>Position Actions</DropdownMenuLabel> */}
          {/* <DropdownMenuSeparator /> */}
          <DropdownMenuGroup>
            {dropdownActions.map((action, idx) => (
              <DropdownMenuItem
                key={idx}
                onClick={action.onClick}
                role="button"
                disabled={action.disabled}
                className={cn('cursor-pointer', action.customClasses)}
              >
                {action.title}
              </DropdownMenuItem>
            ))}

            {/* 
            <DropdownMenuItem onClick={handleOnEdit} role="button" className="cursor-pointer">
              Edit
            </DropdownMenuItem> */}
          </DropdownMenuGroup>
          {/* <DropdownMenuSeparator /> */}

          {/* <DropdownMenuItem className={cn("font-bold cursor-pointer", isOpen && "text-rose-500")} onClick={handleTogglePublishPosition} role="button" >
            {isOpen ? "Close Position" : "Open Position"}


          </DropdownMenuItem> */}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
