import { TCommunicationForm } from '@/fetchers/usePosition';
import {
  positionCommunicationFormDefaultValues,
  PositionCommunicationFormValues,
} from '@/lib/usePositionCommunicationForm';

export const mergeDataWithDefaults = (
  data?: TCommunicationForm
): PositionCommunicationFormValues => {
  return {
    selectedMethod: (data?.selectedMethod ||
      positionCommunicationFormDefaultValues.selectedMethod) as PositionCommunicationFormValues['selectedMethod'],

    afterApplication: (data?.afterApplication ||
      positionCommunicationFormDefaultValues.afterApplication) as PositionCommunicationFormValues['afterApplication'],
    afterApplicationSubject: (data?.afterApplicationSubject ||
      positionCommunicationFormDefaultValues.afterApplicationSubject) as PositionCommunicationFormValues['afterApplicationSubject'],
    afterInterview: (data?.afterInterview ||
      positionCommunicationFormDefaultValues.afterInterview) as PositionCommunicationFormValues['afterInterview'],
    afterInterviewSubject: (data?.afterInterviewSubject ||
      positionCommunicationFormDefaultValues.afterInterviewSubject) as PositionCommunicationFormValues['afterInterviewSubject'],

    interviewType: (data?.interviewType ||
      positionCommunicationFormDefaultValues.interviewType) as PositionCommunicationFormValues['interviewType'],
    interviewDelay: (data?.interviewDelay ||
      positionCommunicationFormDefaultValues.interviewDelay) as PositionCommunicationFormValues['interviewDelay'],

    autoSendOption:
      data?.autoSendOption ||
      positionCommunicationFormDefaultValues.autoSendOption,

    disqualified:
      data?.disqualified || positionCommunicationFormDefaultValues.disqualified,
    disqualifiedSubject: (data?.disqualifiedSubject ||
      positionCommunicationFormDefaultValues.disqualifiedSubject) as PositionCommunicationFormValues['disqualifiedSubject'],

    disqualifiedToggle: data?.disqualified ? 'enabled' : 'disabled',
    autoInterview:
      data?.autoInterview as PositionCommunicationFormValues['autoInterview'],
  };
};
