import { Loader } from '@/components/core/loader';
import { useUserDetails, useUserDetailStore } from '@/fetchers/useUserDetails';
import creatingSvg from '@/assets/svg/creating.svg';
import syncingSvg from '@/assets/svg/undraw_sync.svg';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  createFileRoute,
  ErrorComponent,
  useNavigate,
} from '@tanstack/react-router';
import { ItemList } from '@/pages/dashboard/components/item-list';
import { PlusIcon, SquareArrowOutUpRight } from 'lucide-react';
import {
  TPosition,
  usePaginatedPositionsQuery,
  useUpdatePositionMutation,
} from '@/fetchers/usePosition';
import { Button, buttonVariants } from '@/components/ui/button';
import { cn, getSubtitleFromPosition } from '@/lib/utils';
import { useEffect, useMemo, useState } from 'react';
import AlertModal from '@/components/core/common/AlertModal';
import {
  isAtsAvailableForPlan,
  Plan,
  useATSDisconnect,
  useOrganizationStore,
} from '@/fetchers/useOrganization';
import { ATSConnectionDialog } from '@/components/core/ats';
import { toast } from 'sonner';
import { CONNECTIONS } from '@/components/core/position/details/constants';
import { Separator } from '@/components/ui/separator';
import { SearchInput } from '@/components/core/search-input';
import { Breadcrumb } from '@/components/core/breadcrumb';

export const Route = createFileRoute(
  '/organizations/$organizationId/positions/list'
)({
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: PositionsPage,
});

function PositionsPage() {
  const { organizationId } = Route.useParams();
  const navigate = useNavigate();
  const updatePositionMutation = useUpdatePositionMutation();
  const [closeAlertModelOpen, setCloseAlertModelOpen] = useState(false);
  const [openAlertModel, setOpenAlertModel] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>();
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedOrganization } = useUserDetailStore();
  const [openATSDisconnectAlertModel, setOpenATSDisconnectAlertModel] =
    useState(false);
  const disconnect = useATSDisconnect();

  const { isFetching: isFetchingUserDetails } = useUserDetails();
  const { data: positions, isFetching: isFetchingPositions } =
    usePaginatedPositionsQuery({
      enabled: !isFetchingUserDetails,
      organizationId: Number(organizationId),
    });
  useEffect(() => {
    if (selectedTab !== undefined) return;
    if (!positions || positions?.length === 0) return;

    if (positions?.length === 0) {
      setSelectedTab('all');
      return;
    }

    if (positions?.every((position) => !position.isOpen)) {
      setSelectedTab('all');
      return;
    }

    setSelectedTab('open');
  }, [positions, selectedTab]);

  const filteredItems = useMemo(() => {
    const allItems =
      positions?.map((position) => ({
        slug: position?.slug,
        title: position?.title,
        createdAt: position?.createdAt,
        subtitle: getSubtitleFromPosition(position),
        tags: position.tags,
        content: position.content || '',
        isOpen: position.isOpen,
        applicantCount: position.applicantCount,
      })) || [];

    return allItems.filter((item) => {
      const matchesTitle =
        item.title.toLowerCase().split(searchTerm.toLowerCase()).length > 1;
      const matchesSubtitle =
        item.subtitle.toLowerCase().split(searchTerm.toLowerCase()).length > 1;
      const matchesTags =
        item.tags?.join(' ').toLowerCase().split(searchTerm.toLowerCase())
          .length > 1;
      // const matchesContent = item.content.toLowerCase().split(searchTerm.toLowerCase()).length > 1

      if (selectedTab === 'all') {
        return matchesTitle || matchesSubtitle || matchesTags;
      }

      return (
        item.isOpen === (selectedTab === 'open') &&
        (matchesTitle || matchesSubtitle)
      );
    });
  }, [selectedTab, positions, searchTerm]);

  function handleOnEdit(item: TPosition) {
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step`,
      params: {
        organizationId: String(organizationId),
        slug: item.slug,
        step: selectedOrganization?.integrationName
          ? 'communication'
          : 'details',
      },
    });
  }

  function handleOnClick(item: TPosition) {
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step`,
      params: {
        organizationId: String(organizationId),
        slug: item.slug,
        step: 'applications',
      },
    });
  }
  function handleTogglePublishPosition() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const item: any = closeAlertModelOpen || openAlertModel;

    updatePositionMutation({
      data: {
        isOpen: !item?.isOpen,
      },
      organizationId: Number(organizationId),
      slug: item.slug,
    });
  }

  if (isFetchingPositions) {
    return <Loader />;
  }

  return (
    <>
      <AlertModal
        open={!!closeAlertModelOpen}
        onClose={() => setCloseAlertModelOpen(false)}
        title="You are about to close this position"
        body={`Are you sure you want to close this position? You will not be able to receive new applications for this position.`}
        confirmText="Disable Position"
        onProceed={handleTogglePublishPosition}
      />

      <AlertModal
        open={!!openAlertModel}
        onClose={() => setOpenAlertModel(false)}
        title="You are about to publish this position"
        body={`Once you publish this position, it will be visible to the public and you will start receiving applications for this position.`}
        confirmText="Publish"
        onProceed={handleTogglePublishPosition}
      />

      <AlertModal
        open={!!openATSDisconnectAlertModel}
        onClose={() => setOpenATSDisconnectAlertModel(false)}
        title="You are about to disconnect your ATS Connection"
        body={`Your ATS connection will be disconnected and you will not be able to receive applications from your ATS. Are you sure you want to disconnect?`}
        confirmText="Disconnect"
        onProceed={() =>
          disconnect({
            organizationId: Number(organizationId),
            integrationType: CONNECTIONS.find(
              (c) => c.name === selectedOrganization?.integrationName
            )?.id,
          })
        }
      />

      <Breadcrumb />
      <Tabs
        value={selectedTab}
        className="flex flex-col max-w-2xl xl:max-w-4xl mx-auto w-full h-full"
        onValueChange={(val) => setSelectedTab(val)}
      >
        <div className="flex flex-col mb-4">
          <div className="flex items-center justify-between">
            <h1 className="md:text-xl font-bold">
              Positions{' '}
              {selectedOrganization?.name
                ? `- ${selectedOrganization?.name}`
                : ''}
            </h1>
            {!selectedOrganization?.integrationName && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${selectedOrganization?.slug}${selectedOrganization?.slug === 'careers' ? '' : '.careers'}.veton.ai`}
                className={buttonVariants({ variant: 'outline' })}
              >
                <SquareArrowOutUpRight className="size-4 mr-2" />
                Careers page
              </a>
            )}
          </div>
          <Separator className="my-4" />
          <div className="flex flex-col md:flex-row md:justify-between gap-4">
            <div className="flex gap-4 justify-between md:justify-normal">
              {selectedOrganization?.integrationName ? (
                <Button
                  variant={'outline'}
                  onClick={() => {
                    if (selectedOrganization?.integrationName) {
                      setOpenATSDisconnectAlertModel(true);
                    } else {
                      toast.error('No ATS connection found');
                    }
                  }}
                >
                  Disconnect {selectedOrganization?.integrationName || 'ATS'}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() =>
                    navigate({
                      to: `/organizations/$organizationId/positions/create`,
                      params: { organizationId: String(organizationId) },
                    })
                  }
                >
                  <PlusIcon className="size-5 mr-1" />
                  New Position
                </Button>
              )}
              <TabsList className="">
                <TabsTrigger
                  value="all"
                  className="text-zinc-600 dark:text-zinc-200"
                >
                  All
                </TabsTrigger>
                <TabsTrigger
                  value="open"
                  className="text-zinc-600 dark:text-zinc-200"
                >
                  Open
                </TabsTrigger>
                <TabsTrigger
                  value="closed"
                  className="text-zinc-600 dark:text-zinc-200"
                >
                  Closed
                </TabsTrigger>
              </TabsList>
            </div>
            <SearchInput value={searchTerm} onChange={setSearchTerm} />
          </div>
        </div>

        {filteredItems.length === 0 ? (
          <EmptyView
            title={
              positions?.length === 0
                ? 'No positions found'
                : 'No positions found with the search criteria'
            }
            ctaText={
              positions?.length === 0
                ? 'Create your first position'
                : 'Create a new position'
            }
            subTitle={
              positions?.length === 0
                ? 'Create your first position or connect to your ATS'
                : 'Create a new position that matches the search criteria to get started'
            }
            onClick={() =>
              navigate({
                to: `/organizations/$organizationId/positions/create`,
                params: {
                  organizationId: String(organizationId),
                },
              })
            }
          />
        ) : (
          <>
            <ItemList
              layoutClasses="pb-20 md:pb-6"
              items={filteredItems}
              onClick={(item) =>
                item?.isOpen ? handleOnClick(item) : handleOnEdit(item)
              }
              // handleOnEdit={handleOnEdit}
              // handleTogglePublishPosition={handleTogglePublishPosition}
              dropdownActions={(item: TPosition) => [
                { title: 'Edit', onClick: () => handleOnEdit(item) },
                {
                  title: item?.isOpen ? 'Close Position' : 'Open Position',
                  onClick: () => {
                    if (item?.isOpen) {
                      // @ts-ignore
                      setCloseAlertModelOpen(item);
                    } else {
                      // @ts-ignore
                      setOpenAlertModel(item);
                    }
                  },
                  customClasses: cn(
                    'font-bold cursor-pointer',
                    item?.isOpen && 'text-rose-500'
                  ),
                },
              ]}
            />
          </>
        )}
      </Tabs>
    </>
  );
}

function EmptyView({
  onClick,
  title,
  ctaText,
  subTitle,
}: {
  ctaText: string;
  subTitle: string;
  title: string;
  onClick: () => void;
}) {
  // const { connect, disconnect, isLoading } = useMergeConnect()
  const { selectedOrganization } = useUserDetailStore();
  const { selectedParentOrganization } = useOrganizationStore();

  const parentPlan = selectedParentOrganization?.plan || Plan.FREE;

  if (selectedOrganization?.integrationName) {
    return (
      <div className="w-full flex flex-col items-center justify-center my-12 space-y-8  max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold max-w-sm text-center">
          Syncing in progress
        </h1>
        <img
          src={syncingSvg}
          alt="Syncing in progress"
          style={{
            width: '40%',
            height: 'auto',
          }}
        />

        {/* <p className='max-w-md text-center'>
          Want to disconnect or connect to a different ATS? Click the button below.
        </p>

        <ATSConnectionDialog /> */}
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col items-center justify-center my-12 space-y-8  max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold max-w-sm text-center">{title}</h1>
      <img
        src={creatingSvg}
        alt="Create a new position svg"
        style={{
          width: '40%',
          height: 'auto',
        }}
      />

      <Button onClick={onClick}>
        <PlusIcon className="h-4 w-4 mr-2" />
        {ctaText}
      </Button>

      <p className="max-w-md text-center">
        {!isAtsAvailableForPlan(parentPlan)
          ? 'Create your first board to get started'
          : subTitle}
      </p>

      {isAtsAvailableForPlan(parentPlan) && (
        <>
          <ATSConnectionDialog variant={'outline'} />
        </>
      )}
    </div>
  );
}
