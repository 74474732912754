import { useIntercomMessenger } from '@/lib/useIntercomMessenger';
import { Link } from '@tanstack/react-router';

type TProps = Readonly<{
  statusCode?: number;
  title?: string;
  description?: string;
}>;

export function ErrorView({
  statusCode = 404,
  title = 'Page not found',
  description = 'Sorry, we couldn’t find the page you’re looking for.',
}: TProps) {
  const { openChat } = useIntercomMessenger();
  return (
    <>
      <Link
        to="/"
        className="w-full absolute top-0 z-10 mt-6 mb-4 lg:mt-4 bg-white"
      >
        <div className="container w-full  py-2  flex justify-between  flex-col md:flex-row  ">
          <div className="flex  items-center justify-center ">
            <div className="rounded overflow-hidden size-[60px] ">
              <img
                src={
                  'https://veton-bucket.nyc3.cdn.digitaloceanspaces.com/veton-bucket/organizations/23/logo.png'
                }
                // className="object-fit"
                alt="Company Logo"
                style={{
                  width: '60px',
                  height: '60px',
                  // objectFit: "fill",
                  aspectRatio: 1,
                }}
              />
            </div>

            <div className="flex  flex-col ml-4">
              <p className="font-bold text-2xl">Veton AI</p>
            </div>
          </div>
        </div>
      </Link>

      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-gray-600">{statusCode}</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {title}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {description}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to={'/'}
              className="rounded-md bg-gray-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            >
              Go back home
            </Link>
            <button
              onClick={openChat}
              className="text-sm font-semibold text-gray-900"
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
