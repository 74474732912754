import { Loader } from '@/components/core/loader';
import { useIntercomMessenger } from '@/lib/useIntercomMessenger';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/logout')({
  component: LogoutPage,
});

function LogoutPage() {
  const { auth } = Route.useRouteContext();
  const { closeChat } = useIntercomMessenger();

  useEffect(() => {
    auth.signOut();
    closeChat();
  }, []);

  return <Loader />;
}
