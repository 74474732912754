import { PositionAIConfigFormContainer } from './aiconfig-form-container';
import { Separator } from '@/components/ui/separator';

export default function AIConfigFormView() {
  return (
    <>
      <div>
        <h3 className="text-lg font-medium">AI Configuration</h3>
        <p className="text-sm text-muted-foreground">
          Change AI behavior and configuration for this position
        </p>
      </div>
      <Separator />
      <PositionAIConfigFormContainer />
    </>
  );
}
