import { cn } from '@/lib/utils';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from 'lucide-react';

import { Fragment } from 'react';

type TProps = {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  layoutClasses?: string;
  title?: string;
  style?: object;
  hideOnClickOutside?: boolean;
};

export function ModalDeprecated({
  children,
  open,
  setOpen,
  layoutClasses = '',
  title,
  style = {},
  hideOnClickOutside = true,
}: TProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as={'div'}
        static
        className={cn('fixed z-10 inset-0 overflow-y-auto')}
        open={open}
        onClose={(val) => hideOnClickOutside && setOpen(val)}
      >
        <div
          className={cn(
            'flex items-end justify-center min-h-screen sm:pt-4 sm:px-4 pb-20 text-center sm:block sm:p-0'
          )}
        >
          <Transition.Child
            as={Fragment}
            enter={'ease-out duration-300'}
            enterFrom={'opacity-0'}
            enterTo={'opacity-100'}
            leave={'ease-in duration-200'}
            leaveFrom={'opacity-100'}
            leaveTo={'opacity-0'}
          >
            <Dialog.Overlay
              className={cn(
                'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
              )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className={cn('hidden xs:inline-block xs:align-middle xs:h-screen')}
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter={'ease-out duration-300'}
            enterFrom={'opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95'}
            enterTo={'opacity-100 translate-y-0 xs:scale-100'}
            leave={'ease-in duration-200'}
            leaveFrom={'opacity-100 translate-y-0 xs:scale-100'}
            leaveTo={'opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95'}
          >
            <div
              style={style}
              className={cn(
                'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all my-8 xs:align-middle  xs:p-6',
                layoutClasses
              )}
            >
              <div className={cn('block absolute top-0 right-0 pt-4 pr-4')}>
                <button
                  type="button"
                  className={cn(
                    'bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  )}
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className={cn('h-6 w-6')} aria-hidden="true" />
                </button>
              </div>

              {title && (
                <Dialog.Title
                  as={'h3'}
                  className={cn('text-lg leading-6 font-medium text-gray-900')}
                >
                  {title}
                </Dialog.Title>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
